import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

function Animate({ children }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
      easing: "ease",
    })
  }, [])
  return <div>{children}</div>
}

export default Animate
