import React, { useEffect, useState, useRef } from "react"
import PageLayout from "../components/Page_layout"
import "./mystyle.css"
import ProjectOutput from "../components/project-output"
import ProjectOverview from "../components/project-overview"
import PhotoGalleries from "../components/photo-galleries"
import About from "../components/about"
import ProjectCoverage from "../components/project-coverage"
import Notification from "../components/notification"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Fireworks from "fireworks-js" // Correct import

const Article = () => {
  const data = useStaticQuery(graphql`
    query {
      allNodeCarousel {
        nodes {
          id
          relationships {
            field_sliders {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 4160
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const fieldSliders = data.allNodeCarousel.nodes.flatMap(
    node => node.relationships.field_sliders
  )

  const [showMessage, setShowMessage] = useState(false)

  // Ref for fireworks container
  const fireworksContainerRef = useRef(null)

  useEffect(() => {
    const now = new Date()
    const currentYear = now.getFullYear()

    // Define the start and end dates for the message display
    const startDate = new Date(`${currentYear}-01-01`)
    const endDate = new Date(`${currentYear}-01-31T23:59:59`)
    // Check if the current date is within the range
    if (now >= startDate && now <= endDate) {
      // Check sessionStorage to see if the message has been shown in this session
      const hasSeenMessageInSession = sessionStorage.getItem(
        "hasSeenChristmasMessage"
      )

      if (!hasSeenMessageInSession) {
        setShowMessage(true)
        sessionStorage.setItem("hasSeenChristmasMessage", "true") // Mark as seen for the session
      }
    }
  }, [])

  useEffect(() => {
    // Initialize fireworks when the message is shown
    let fireworks
    if (showMessage && fireworksContainerRef.current) {
      fireworks = new Fireworks(fireworksContainerRef.current, {
        // Custom firework colors
        fireworks: [
          {
            hue: { min: 0, max: 360 }, // Randomize color hue (for a variety of colors)
            particles: 150, // Number of particles
            speed: 2, // Speed of the fireworks
            size: 3, // Size of the fireworks
            trail: 4, // Particle trail length
            opacity: 0.9, // Opacity of the fireworks
            delay: 0.15, // Delay before starting
            acceleration: 1.05, // Acceleration of fireworks
            duration: 3, // Duration of the fireworks
          },
        ],
        // Customize other properties like gravity, speed, etc.
        speed: 3,
        particles: 200,
      })
      fireworks.start()
    }

    // Cleanup fireworks on message close
    return () => {
      if (fireworks) {
        fireworks.stop() // Stop the fireworks when cleaning up
      }
    }
  }, [showMessage])

  // Handler to close the message
  const handleCloseMessage = () => {
    setShowMessage(false)
  }

  return (
    <div>
      <PageLayout>
        <div className="slider-container my-3">
          <div className="slider-wrapper flex gap-x-3 z-10 relative">
            {fieldSliders.map((slider, index) => (
              <div key={index} className="overflow-hidden self-center">
                <GatsbyImage
                  image={getImage(
                    slider.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt={`Slider Image ${index}`}
                  className="w-[400px] lg:h-screen h-[480px] hover:h-[480px] hover:w-[800px] hover:lg:h-screen rounded transition-all duration-1000 grow"
                />
              </div>
            ))}
            {fieldSliders.map((slider, index) => (
              <div
                key={`clone-${index}`}
                className="overflow-hidden self-center"
              >
                <GatsbyImage
                  image={getImage(
                    slider.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt={`Cloned Slider Image ${index}`}
                  className="w-[400px] lg:h-screen h-[480px] hover:h-[480px] hover:w-[800px] hover:lg:h-screen rounded transition-all duration-1000 grow"
                />
              </div>
            ))}
          </div>
        </div>
        <div
          ref={fireworksContainerRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none", // Allow interaction with the slider images
            zIndex: 20, // Place fireworks in front
          }}
        ></div>

        {/* Fireworks message */}
        {showMessage && (
          <div
            onClick={handleCloseMessage} // Close the message by clicking anywhere
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 30, // Ensure the message is in front of everything else
            }}
          >
            <div
              className="lg:px-20 px-3 text-white space-y-5"
              onClick={e => e.stopPropagation()} // Prevent closing when clicking the content
            >
              <h1 className="text-center text-4xl">
                🎉 Happy New Year 2025 🎊
              </h1>
              <div className="space-y-3 text-center text-xl">
                <p>
                  May this year bring you success, joy, and prosperity.
                  Together, let’s make 2025 an incredible year!
                </p>
                <p>
                  Warm regards,
                  <br />
                  The MegARISE Team
                </p>
              </div>
              <center>
                <button
                  onClick={handleCloseMessage} // Close using the button
                  className="mt-5 px-5 py-2 bg-red-600 hover:bg-red-700 text-white rounded"
                >
                  Close
                </button>
              </center>
            </div>
          </div>
        )}
        <Notification />
        <div className="my-20 space-y-20 overflow-hidden">
          <div>
            <About />
          </div>
          <div>
            <ProjectOutput />
          </div>
          <div>
            <ProjectOverview />
          </div>
          <div>
            <ProjectCoverage />
          </div>
          <div>
            <PhotoGalleries />
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default Article
