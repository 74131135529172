import React from "react"
import PageLayout from "../components/Page_layout"

function GISDashboard() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div>
          <iframe
            height="1000px"
            width="100%"
            src="https://meghalayacmdashboard.in/gisportal/apps/dashboards/5767b43044e6490aaad76262b9bb223b"
          ></iframe>
        </div>
      </PageLayout>
    </div>
  )
}

export default GISDashboard
