import React from "react"
import PageLayout from "../components/Page_layout"

function ReelsVideos() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20 lg:px-10 lg:py-10 py-5 px-3 border shadow rounded-3xl bg-white">
          <p className="text-center">Contents will be available soon</p>
        </div>
      </PageLayout>
    </div>
  )
}

export default ReelsVideos
