import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function Copyright() {
  return (
    <div className="py-2 space-y-3 overflow-hidden bg-white">
      <div className="flex lg:gap-x-20 gap-x-5 justify-center">
        <Link to="https://mbda.gov.in/" target="__blank">
          <StaticImage
            src="../images/MBDA.png"
            alt="meglife"
            className="object-contain lg:w-20 w-10 lg:h-20 h-10"
          />
        </Link>
        <Link
          to="https://www.mbma.org.in/"
          target="__blank"
          className="self-center"
        >
          <StaticImage
            src="../images/mbma.png"
            alt="meglife"
            className="object-contain lg:w-56 w-28 lg:h-14 h-7"
          />
        </Link>
        <Link to="https://meghalaya.gov.in/" target="__blank">
          <StaticImage
            src="../images/govt_of_meg.png"
            alt="meglife"
            className="object-contain lg:w-20 w-10 lg:h-20 h-10"
          />
        </Link>
        <Link to="#" target="__blank">
          <StaticImage
            src="../images/german_cooperation.jpg"
            alt="meglife"
            className="object-contain lg:w-20 w-10 lg:h-20 h-10"
          />
        </Link>
        <Link
          to="https://www.kfw-entwicklungsbank.de/International-financing/KfW-Entwicklungsbank/"
          target="__blank"
        >
          <StaticImage
            src="../images/kfw.jpg"
            alt="meglife"
            className="object-contain lg:w-20 w-10 lg:h-20 h-10"
          />
        </Link>
      </div>
      <p className="font-bold text-sm text-slate-900 text-center">
        © 2024, MegARISE. All rights reserved
      </p>
    </div>
  )
}

export default Copyright
