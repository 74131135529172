import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import PageLayout from "../components/Page_layout"

const PDFDisplayPage = () => {
  const location = useLocation()
  const [pdfUrl, setPdfUrl] = useState(null)
  const [title, setTitle] = useState("")

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const pdfUrlParam = query.get("pdfUrl")
    const titleParam = query.get("title")
    setPdfUrl(pdfUrlParam)
    setTitle(titleParam || "PDF Preview")
  }, [location])

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20">
          {pdfUrl ? (
            <iframe src={pdfUrl} title={title} className="w-full h-[1000px]" />
          ) : (
            <p>No PDF available</p>
          )}
        </div>
      </PageLayout>
    </div>
  )
}

export default PDFDisplayPage
