import React from "react"
import "./nav.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import { format, differenceInDays } from "date-fns"

function Notification() {
  const data = useStaticQuery(graphql`
    query {
      allNodeNotification(sort: { field_date: DESC }, limit: 10) {
        nodes {
          field_date
          field_last_date
          title
          path {
            alias
          }
        }
      }
    }
  `)

  const notice = data.allNodeNotification.nodes.filter(node => {
    // Only filter out notifications if `field_last_date` exists and is in the past
    if (node.field_last_date) {
      const lastDate = new Date(node.field_last_date)
      // Reset both dates to midnight
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      lastDate.setHours(0, 0, 0, 0)
      return today <= lastDate
    }
    // Include notifications that don't have `field_last_date`
    return true
  })

  return (
    <div className="border h-full border-[#252529] flex lg:mx-20 mx-3">
      <p className="bg-[#252529] text-center text-white text-xl font-semibold px-3 py-2">
        Notifications
      </p>
      <div className="overflow-hidden self-center">
        <div className="marquee-content">
          {notice.map((node, index) => {
            const dateDifference = differenceInDays(
              new Date(),
              new Date(node.field_date)
            )
            return (
              <div key={index} className="px-5 inline-block">
                <Link
                  to={node.path.alias}
                  className="flex gap-x-1 items-center hover:text-blue-500"
                >
                  <p>{format(new Date(node.field_date), "dd-MM-yyyy")}</p>
                  <div className="flex gap-x-1">
                    <span>{node.title}</span>
                    {dateDifference <= 10 && (
                      <p className="text-red-700 font-extrabold text-xs animate-pulse">
                        new
                      </p>
                    )}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Notification
