import React, { useState } from "react"

function PRAGanol() {
  const [readmore, setReadmore] = useState(false)
  return (
    <div className="space-y-3">
      <h1 className="text-center font-bold">
        Participatory Rural Appraisal (PRA)
      </h1>
      <p className="text-justify leading-8">
        In the context of the MegARISE Project, a comprehensive PRA is
        envisioned to facilitate the development of Village-Level Micro Action
        Plans (VLMAP). Specific PRA tools are used for understanding the
        village's socio-economic context, livelihood opportunities, land use
        patterns, and natural resource availability. Those PRA tools are
        resource maps, social maps, Venn diagrams, seasonal calendars, trend
        change, SWOT analysis, historical timelines, forest maps, ownership
        maps, chapati clocks, and more.
      </p>
      <button
        onClick={() => setReadmore(!readmore)}
        className={`text-blue-500 text-xs hover:text-blue-950 ${
          readmore ? "hidden" : "block"
        }`}
      >
        Read more
      </button>
      {readmore && (
        <p className="text-justify leading-8">
          The MegARISE teams dedicate approximately two days to the PRA phase,
          laying the foundation for subsequent FPA activities. The third day is
          summarized, and the first FPA contest is conducted if the communities
          are willing and ready. The announcement for the first FPA contest is
          also made at the end of PRA. So far, the Ganol Catchment Management
          Unit held 14 PRA.
        </p>
      )}
    </div>
  )
}

export default PRAGanol
