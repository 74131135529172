import React, { useState } from "react"

function FPAGanol() {
  const [readmore, setReadmore] = useState(false)
  return (
    <div className="space-y-3">
      <h1 className="text-center font-bold">
        Financial Participatory approach (FPA)
      </h1>
      <p className="text-justify leading-8">
        The Financial Participatory Approach (FPA) is one of the new approaches
        for communities to drive their willingness & participation through
        competitions, awards, and inclusive engagement. Also, FPA promotes
        trust, confidence, and active participation, making it a valuable tool
        for community-led projects. The Catchment Management Unit organizes 3
        levels of the FPA Contest.
      </p>
      <button
        onClick={() => setReadmore(!readmore)}
        className={`text-blue-500 text-xs hover:text-blue-950 ${
          readmore ? "hidden" : "block"
        }`}
      >
        Read more
      </button>
      {readmore && (
        <div className="space-y-3 text-justify">
          <ul className="list-decimal space-y-3 px-5">
            <li>
              FPA contest-1: Drawing and Story Telling Competition for the
              children to participate in the drawing competition. Whereas the
              storytelling competition is for the youths of the village.{" "}
            </li>
            <li>
              FPA Contest-2: On the “Sustainable Livelihood” theme. The
              community participates and shares their ideas with the jury
              members and the winner gets a cash prize.
            </li>
            <li>
              FPA Contest-3: On the “Community Infrastructure for Soil and Water
              Conservation Measures” theme, the community can participate and
              present their ideas.
            </li>
          </ul>
          <p className="leading-8">
            Organizing the FPA contest helps to capture information related to
            villages covered, winners' details, gender-wise participants,
            winning ideas, and all those ideas generated during FPA Contest-2
            and FPA Contest-3 for further integration during VLMAP preparation.
            So far the Ganol Catchment Management Unit conducted eight (8) FPA
            Contest-2 and eight (8) FPA Contest-3.
          </p>
        </div>
      )}
    </div>
  )
}

export default FPAGanol
