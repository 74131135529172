import React, { useState } from "react"
import OrgChart from "react-orgchart"
import "react-orgchart/index.css"
import Layout from "../components/Page_layout.js"
import { Link } from "gatsby"

const data = {
  name: "Governing Council",
  title: "",
  backgroundColor: "bg-slate-300",
  hoverColor: "hover:bg-slate-500",
  hoverText: "hover:text-white",
  rounded: "rounded-full",
  link: "https://megarise.in/who's-who/?tab=Governing%20Council", // Link for this node
  children: [
    {
      name: "Project Steering Committee",
      title: "",
      backgroundColor: "bg-slate-300",
      hoverColor: "hover:bg-slate-500",
      hoverText: "hover:text-white",
      rounded: "rounded-full",
      link: "https://megarise.in/who's-who/?tab=Project%20Steering%20Committee",
      children: [
        {
          name: "State Project Management Unit",
          title: "",
          backgroundColor: "bg-slate-300",
          hoverColor: "hover:bg-slate-500",
          hoverText: "hover:text-white",
          rounded: "rounded-full",
          padding: "lg:px-0 px-12",
          marginl: "mr-3",
          link: "https://megarise.in/who's-who/?tab=State%20Project%20Management%20Unit",
          children: [
            {
              name: "Ganol Catchment Management Unit",
              title: "",
              backgroundColor: "bg-slate-300",
              hoverColor: "hover:bg-slate-500",
              hoverText: "hover:text-white",
              rounded: "rounded-full",
              marginr: "mr-3",
              link: "https://megarise.in/who's-who/?tab=Ganol%20Catchment%20Management%20Unit",
              children: [
                {
                  name: "Village Project Implementation Committee",
                  title: "",
                  backgroundColor: "bg-slate-300",
                  hoverColor: "hover:bg-slate-500",
                  hoverText: "hover:text-white",
                  rounded: "rounded-full",
                  marginr: "mr-3",
                  link: "#",
                },
              ],
            },
            {
              name: "Umiew Catchment Management Unit",
              title: "",
              backgroundColor: "bg-slate-300",
              hoverColor: "hover:bg-slate-500",
              hoverText: "hover:text-white",
              rounded: "rounded-full",
              marginl: "ml-3",
              link: "https://megarise.in/who's-who/?tab=Umiew%20Catchment%20Management%20Unit",
              children: [
                {
                  name: "Village Project Implementation Committee",
                  title: "",
                  backgroundColor: "bg-slate-300",
                  hoverColor: "hover:bg-slate-500",
                  hoverText: "hover:text-white",
                  rounded: "rounded-full",
                  marginl: "ml-3",
                  link: "#",
                },
              ],
            },
          ],
        },
        {
          name: "Implementation Consultant",
          title: "",
          backgroundColor: "bg-slate-300",
          hoverColor: "hover:bg-slate-500",
          hoverText: "hover:text-white",
          rounded: "rounded-full",
          marginr: "ml-3",
          link: "https://megarise.in/who's-who/?tab=Implementation%20Consultant",
        },
      ],
    },
  ],
}

const HierarchyChart = () => {
  const [selectedNode, setSelectedNode] = useState(null)

  const handleNodeClick = node => {
    setSelectedNode(node)
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <Layout>
        <center>
          <div className="lg:mx-20 mx-3 my-20 lg:px-10 px-3 lg:py-10 py-5 lg:overflow-x-hidden overflow-x-scroll space-y-5 border shadow rounded-3xl bg-white">
            <p className="text-2xl font-bold text-center">
              Implementation Structure
            </p>
            <OrgChart
              tree={data}
              NodeComponent={({ node }) => (
                <div
                  className={`py-3 px-3 lg:text-xl font-bold cursor-pointer ${node.hoverText} ${node.marginl} ${node.marginr} ${node.padding} ${node.backgroundColor} ${node.hoverColor} ${node.rounded}`}
                >
                  {node.link ? (
                    <Link to={node.link}>
                      <div>{node.name}</div>
                    </Link>
                  ) : (
                    <div>{node.name}</div>
                  )}
                </div>
              )}
              onNodeClick={handleNodeClick}
            />
          </div>
        </center>
      </Layout>
    </div>
  )
}

export default HierarchyChart
