import React, { useEffect, useState } from "react"

const isBrowser = typeof window !== "undefined"

const AnalyticsDashboard = () => {
  const [analyticsData, setAnalyticsData] = useState({
    totalActiveUsers: null,
    totalEventCount: null,
    totalPageViewUsers: null,
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!isBrowser) return // Prevent API call during SSR

    const fetchAnalytics = async () => {
      try {
        const response = await fetch(
          `${process.env.GOOGLE_API_URL}api/analytics`
        )
        if (!response.ok) throw new Error("Failed to fetch analytics")
        const data = await response.json()
        setAnalyticsData(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchAnalytics()
  }, [])

  if (loading) return <div>Loading analytics data...</div>
  if (error) return <div>Error: {error}</div>
  if (!analyticsData.totalActiveUsers) return <div>No data available</div>

  return (
    <div className="text-white flex gap-3">
      <p>
        Total number of page visited by the users:{" "}
        {analyticsData.totalEventCount}
      </p>
      <p>Total numbers of visited Users: {analyticsData.totalPageViewUsers}</p>
    </div>
  )
}

export default AnalyticsDashboard
