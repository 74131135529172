import { Link, graphql } from "gatsby"
import React, { useState } from "react"
import { Tab, TabList, Tabs } from "react-tabs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons"
import PageLayout from "../components/Page_layout"

function Tender({ data }) {
  const tender = data.allTender.nodes
  const award = data.allAward.nodes

  const [tabIndex, setTabIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterByDate, setFilterByDate] = useState("")

  const filteredTender = tender
    .filter(tenders =>
      filterByDate ? tenders.field_date === filterByDate : true
    )
    .sort((a, b) => {
      // Assuming notice. field_date_tender is in the format "DD-MM-YYYY"
      const aDateStr = a.field_date
        ? a.field_date.split("-").reverse().join("-")
        : null
      const bDateStr = b.field_date
        ? b.field_date.split("-").reverse().join("-")
        : null

      const dateA = aDateStr ? new Date(aDateStr) : null
      const dateB = bDateStr ? new Date(bDateStr) : null
      return dateB - dateA // Descending order
    })
    .slice(0, rowsPerPage)

  const handleFilterByDateChange = event => {
    const selectedDate = event.target.value
    setFilterByDate(selectedDate)

    // Convert selected date to "DD-MM-YYYY" format
    const formattedDate = selectedDate
      ? selectedDate.split("-").reverse().join("-")
      : ""

    setFilterByDate(formattedDate)
  }

  const filteredAward = award
    .filter(tenders =>
      filterByDate ? tenders.field_date === filterByDate : true
    )
    .sort((a, b) => {
      // Assuming notice. field_date_tender is in the format "DD-MM-YYYY"
      const aDateStr = a.field_date
        ? a.field_date.split("-").reverse().join("-")
        : null
      const bDateStr = b.field_date
        ? b.field_date.split("-").reverse().join("-")
        : null

      const dateA = aDateStr ? new Date(aDateStr) : null
      const dateB = bDateStr ? new Date(bDateStr) : null
      return dateB - dateA // Descending order
    })
    .slice(0, rowsPerPage)

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 flex flex-col">
          <h2 className="text-2xl text-center font-bold">
            {tabIndex === 0
              ? "Tenders"
              : tabIndex === 1
              ? "Award of Contract"
              : ""}
          </h2>
          <div className="flex lg:flex-row flex-col justify-between gap-y-2 mt-10">
            <div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList className="flex gap-x-2">
                  {["Tender", "Award of Contract"].map((tabTitle, index) => (
                    <Tab
                      key={index}
                      className={`px-5 py-2 cursor-pointer text-white bg-slate-900 ${
                        index === tabIndex ? "border-slate-900 border-b-4 text-white" : ""
                      }`}
                    >
                      {tabTitle}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </div>
            <div className="flex gap-x-2">
              <h2 className="self-center">Filter</h2>
              <select
                id="rowsPerPage"
                onChange={e => setRowsPerPage(Number(e.target.value))}
                className="sm:px-0 px-2 sm:py-0 py-2 border border-gray-300 rounded"
              >
                <option value={1}>1</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                {/* Add more options if needed */}
              </select>
              <input
                type="date"
                id="filterByDate"
                onChange={handleFilterByDateChange}
                value={filterByDate}
                className="border border-gray-300 rounded"
              />
            </div>
          </div>
          {tabIndex === 0 && (
            <table className="mt-1 custom-table bg-white">
              <thead className="bg-black text-white font-bold">
                <tr className="text-center text-white">
                  <td>Sl. No</td>
                  <td>Date</td>
                  <td>Title</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {filteredTender.map((nodes, index) => (
                  <tr key={nodes.title}>
                    <td className="w-fit text-center">{index + 1}</td>
                    <td className="w-fit text-center">{nodes.field_date}</td>
                    <td>{nodes.title}</td>
                    <td className="w-fit">
                      <Link
                        to={nodes.path.alias}
                        className="hover:text-blue-500 text-center"
                      >
                        <b>
                          {" "}
                          <FontAwesomeIcon icon={faFileAlt} className="px-2" />
                        </b>
                        View More
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {tabIndex === 1 && (
            <table className="mt-1 custom-table bg-white">
              <thead className="bg-black text-white font-bold">
                <tr>
                  <td className="text-center text-white border">Sl. No</td>
                  <td className="text-center text-white border">Date</td>
                  <td className="text-center text-white border">Title</td>
                  <td className="text-center text-white border">Action</td>
                </tr>
              </thead>
              <tbody>
                {filteredAward.map((nodes, index) => (
                  <tr key={nodes.title}>
                    <td className="w-fit text-center">{index + 1}</td>
                    <td className="w-fit text-center">{nodes.field_date}</td>
                    <td>{nodes.title}</td>
                    <td className="w-fit">
                      <Link
                        to={nodes.path.alias}
                        className="hover:text-blue-500 text-center"
                      >
                        <b>
                          {" "}
                          <FontAwesomeIcon icon={faFileAlt} className="px-2" />
                        </b>
                        View More
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </PageLayout>
    </div>
  )
}

export const query = graphql`
  query {
    allTender: allNodeNotification(
      sort: { field_date: DESC }
      filter: { field_select_one_notice: { eq: "tender" } }
    ) {
      nodes {
        title
        path {
          alias
        }
        field_date(formatString: "DD-MM-YYYY")
      }
    }
    allAward: allNodeNotification(
      sort: { field_date: DESC }
      filter: { field_select_one_notice: { eq: "recruitment" } }
    ) {
      nodes {
        title
        path {
          alias
        }
        field_date(formatString: "DD-MM-YYYY")
      }
    }
  }
`

export default Tender
