import React from "react"
import PageLayout from "../components/Page_layout"

function Disclaimer() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 border shadow sm:px-5 px-3 py-5 space-y-5 rounded-3xl bg-white">
          <p className="text-2xl font-bold text-center">Disclaimer</p>
          <p className="text-justify leading-8">
            This website is operated by MegARISE. The contents of this website
            are for informational purposes only so that the general public can
            get the information with ease and accessibility to the general
            public and does not have any statutory sanctity. Though every effort
            has been made to provide accurate and up-to-date information, there
            is a possibility that some information such as telephone no., The
            phone no., name of the officer holding any post, etc. may have
            changed before the website is updated. Therefore, we do not claim
            any statutory liability for the completeness, accuracy or usefulness
            of the contents provided in this website. Off-site links are also
            provided in some web pages/documents. We take no responsibility for
            the accuracy of the content provided in those sites. Hyperlinks
            provided to external sites do not imply any endorsement of the
            services or products, information, etc., provided by those sites.
            Despite our best efforts, we do not guarantee that the documents
            provided on this site are free from infection with computer viruses,
            etc. We welcome your suggestions for further improvement of this
            site and request you to bring any errors (if any) to our attention.
          </p>
        </div>
      </PageLayout>
    </div>
  )
}

export default Disclaimer
