import React from "react"
import PageLayout from "../components/Page_layout"

function WebsitePolicy() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 border shadow sm:px-5 px-3 py-5 space-y-5 rounded-3xl bg-white">
          <p className="text-2xl font-bold text-center">Website Policy</p>
          <p className="text-justify leading-8">
            This is the Official website of MegARISE The site has been developed
            to provide information to the general public. An attempt has been
            made through this site to provide reliable, comprehensive, accurate
            information about MegARISE and its various Groups/Organizations.
            Hyperlinks at various places have been provided to other Indian
            Government Portals/Websites. The content in this site is the result
            of a collaborative effort of various Groups and Divisions of the
            MBDA. It is our endeavor to continue the enhancement and enrichment
            of this site in terms of content coverage, design and technology on
            a regular basis.
          </p>
        </div>
      </PageLayout>
    </div>
  )
}

export default WebsitePolicy
