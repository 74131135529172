import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"

function About() {
  const data = useStaticQuery(graphql`
    query {
      allNodePage(
        filter: { id: { eq: "68ed491a-71c1-5221-b9b5-6172584c30ef" } }
      ) {
        nodes {
          body {
            processed
          }
        }
      }
    }
  `)

  const article = data.allNodePage.nodes

  return (
    <div>
      {article.map(e => (
        <div className="space-y-3 lg:mx-20 mx-3">
          <p className="text-3xl">
            <span>Meg</span>
            <span className="font-bold">ARISE</span>
          </p>
          <p
            dangerouslySetInnerHTML={{ __html: e.body.processed }}
            className="leading-8 text-justify lg:columns-2 columns-1 gap-x-10"
          />
        </div>
      ))}
    </div>
  )
}

export default About
