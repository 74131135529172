import React from "react"
import PageLayout from "../components/Page_layout"

function TermCondition() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 border shadow sm:px-5 px-3 py-5 space-y-5 rounded-3xl bg-white">
          <p className="text-2xl font-bold text-center">Terms and Condition</p>
          <p className="text-justify leading-8">
            This official website of the MegARISE Meghalaya, Government of
            India” has been developed to provide information to the general
            public. The documents and information displayed in this website are
            for reference purposes only and does not purport to be a legal
            document. MegARISE does not warrant the accuracy or completeness of
            the information, text, graphics, links or other items contained
            within the Website. As a result of updates and corrections, the web
            contents are subject to change without any notice from ” MegARISE ”
            at MegARISE website. In case of any variance between what has been
            stated and that contained in the relevant Act, Rules, Regulations,
            Policy Statements etc., the latter shall prevail. Any specific
            advice or replies to queries in any part of the website is/are the
            personal views / opinion of such experts/consultants/persons and are
            not necessarily subscribed to by MBDA or its websites. Certain links
            on the website lead to resources located on other websites
            maintained by third parties over whom MegARISE has no control or
            connection. These websites are external to MegARISE and by visiting
            these; you are outside the MegARISE website and its channels.
            MegARISE neither endorses in any way nor offers any judgment or
            warranty and accepts no responsibility or liability for the
            authenticity, availability of any of the goods or services or for
            any damage, loss or harm, direct or consequential or any violation
            of local or international laws that may be incurred by your visiting
            and transacting on these websites.
          </p>
        </div>
      </PageLayout>
    </div>
  )
}

export default TermCondition
