import React, { useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import districtData from "./garo.json" // Ensure the correct path
import PageLayout from "../components/Page_layout"

// Helper function to group data by district and block
const groupByDistrictAndBlock = data => {
  const grouped = {}

  data.forEach(item => {
    const { District, Block, "Village Name": village } = item

    if (!grouped[District]) grouped[District] = {}
    if (!grouped[District][Block]) grouped[District][Block] = []

    grouped[District][Block].push(village)
  })

  return grouped
}

const groupedData = groupByDistrictAndBlock(districtData.Sheet1)

const DistrictTabs = () => {
  const [activetab, setActiveTab] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20">
          {/* Top-level Tabs: Districts */}
          <Tabs
            selectedIndex={activetab}
            onSelect={setActiveTab}
            className="space-y-10"
          >
            <TabList className="grid grid-cols-3 gap-x-5 text-center lg:text-2xl text-lg text-white font-bold">
              {Object.keys(groupedData).map((district, index) => (
                <Tab
                  key={index}
                  className={`cursor-pointer hover:bg-white hover:border-b-4 border-slate-900 hover:text-black py-2 ${
                    activetab === index
                      ? "bg-white text-black border-b-4 border-slate-900"
                      : "bg-slate-900"
                  }`}
                >
                  {district}
                </Tab>
              ))}
            </TabList>
            {Object.entries(groupedData).map(
              ([district, blocks], districtIndex) => (
                <TabPanel key={districtIndex}>
                  <Tabs className="space-y-5">
                    <TabList className="flex justify-center gap-x-5 lg:text-xl text-center">
                      {Object.keys(blocks).map((block, blockIndex) => (
                        <Tab
                          key={blockIndex}
                          className="cursor-pointer border-b-4 hover:border-slate-900 px-5 py-2"
                        >
                          {block} BLOCK
                        </Tab>
                      ))}
                    </TabList>
                    {Object.entries(blocks).map(
                      ([block, villages], blockIndex) => (
                        <TabPanel key={blockIndex}>
                          <table className="bg-white custom-table">
                            <thead>
                              <tr className="bg-black text-white">
                                <th className="w-20">Sl. No.</th>
                                <th className="flex justify-between items-center">
                                  <p className="self-center">
                                    Total Number of Villages is
                                    <span className="pl-1">
                                      {
                                        villages.filter(village =>
                                          village
                                            .toLowerCase()
                                            .startsWith(searchTerm.toLowerCase())
                                        ).length
                                      }
                                    </span>
                                  </p>
                                  <input
                                    type="text"
                                    placeholder="Search villages..."
                                    value={searchTerm}
                                    onChange={e =>
                                      setSearchTerm(e.target.value)
                                    }
                                    className="px-3 text-black py-2 lg:w-1/4 w-1/2"
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {villages
                                .filter(village =>
                                  village
                                    .toLowerCase()
                                    .startsWith(searchTerm.toLowerCase())
                                )
                                .sort((a, b) => a.localeCompare(b))
                                .map((village, vIdx) => (
                                  <tr key={vIdx}>
                                    <td className="text-center">{vIdx + 1}</td>
                                    <td>{village}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </TabPanel>
                      )
                    )}
                  </Tabs>
                </TabPanel>
              )
            )}
          </Tabs>
        </div>
      </PageLayout>
    </div>
  )
}

export default DistrictTabs
