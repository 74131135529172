import React from "react"
import { useLocation } from "@reach/router"
import PageLayout from "../components/Page_layout"

function ViewPPT() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const fileUrl = params.get("file")
  const title = params.get("title")

  if (!fileUrl) {
    return <div>No file specified</div>
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20 flex flex-col items-center justify-center bg-gray-100">
          <h1 className="text-2xl font-bold mb-5">
            {title}
          </h1>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              fileUrl
            )}`}
            width="100%"
            height="500"
            frameBorder="0"
            title="PPT Viewer"
            className="border-2 border-gray-300 rounded-lg"
          />
        </div>
      </PageLayout>
    </div>
  )
}

export default ViewPPT
