import React, { useEffect, useState } from "react"
import Footer from "./footer"
import Navbar from "./header"
import Copyright from "./copyright"
import Logo from "./logo"
import ScrollToTop from "react-scroll-to-top"
import "./nav.css"

const PageLayout = ({ children }) => {
  const [showDiv, setShowDiv] = useState(true) // Default to true for initial visibility
  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    let timer = null // To debounce scroll events

    const handleScroll = () => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        const currentScrollY = window.scrollY

        if (currentScrollY < lastScrollY) {
          // Scrolling up
          setShowDiv(true)
        } else {
          // Scrolling down
          setShowDiv(false)
        }

        setLastScrollY(currentScrollY)
      }, 10) // Adjust debounce time as needed
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      if (timer) clearTimeout(timer)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollY])

  return (
    <div>
      <title>MegARISE</title>
      <div
        className={`sticky top-0 z-50 bg-white transition-transform duration-300 ${
          showDiv ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <Logo />
        <Navbar />
      </div>
      <div>{children}</div>
      <Footer />
      <Copyright />
      <ScrollToTop
        smooth
        style={{
          backgroundColor: "#252529",
          padding: "5px",
          right: "20px",
          bottom: "20px",
        }}
        color="white"
      />
    </div>
  )
}

export default PageLayout
