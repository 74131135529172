import React, { useEffect, useState } from "react"
import Layout from "../components/Page_layout"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import "../pages/table.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function WhosWho() {
  const [activeTab, setActiveTab] = useState(0)
  const tabNames = [
    "Governing Council",
    "Project Steering Committee",
    "State Project Management Unit",
    "Ganol Catchment Management Unit",
    "Umiew Catchment Management Unit",
    "Implementation Consultant",
  ]

  const tabIds = [
    "20a1bacd-3b94-5477-b2ec-52e65f14c201",
    "d4e1df3a-514b-599d-8823-57c0605ef7a7",
    "e6a63234-ae95-52b2-a35a-4fb7f0fb59eb",
    "d13d8a58-30b6-5c59-8d1b-f779ae41d786",
    "c80921c0-bc87-5d16-971c-4fdf14a129c2",
    "4112f078-d346-50e9-80a6-0dec5d04c0ab",
  ]

  const data = useStaticQuery(graphql`
    query {
      allNodePage(
        filter: {
          id: {
            in: [
              "20a1bacd-3b94-5477-b2ec-52e65f14c201"
              "d4e1df3a-514b-599d-8823-57c0605ef7a7"
              "e6a63234-ae95-52b2-a35a-4fb7f0fb59eb"
              "d13d8a58-30b6-5c59-8d1b-f779ae41d786"
              "c80921c0-bc87-5d16-971c-4fdf14a129c2"
              "4112f078-d346-50e9-80a6-0dec5d04c0ab"
            ]
          }
        }
      ) {
        nodes {
          title
          id
          body {
            processed
          }
          field_body {
            processed
          }
          relationships {
            field_images {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const who = data.allNodePage.nodes

  useEffect(() => {
    if (typeof window !== "undefined") {
      const query = new URLSearchParams(window.location.search)
      const tabQuery = query.get("tab")

      // Set active tab based on URL query
      const tabIndex = tabQuery ? tabNames.indexOf(tabQuery) : 0
      setActiveTab(tabIndex !== -1 ? tabIndex : 0)
    }
  }, [tabNames])

  const handleTabSelect = index => {
    setActiveTab(index)
    const selectedTab = tabNames[index]
    // Update the URL with query parameter
    if (typeof window !== "undefined") {
      const newUrl = `?tab=${encodeURIComponent(selectedTab)}`
      window.history.pushState(null, "", newUrl)
    }
  }

  const renderTabContent = id => {
    const content = who.find(item => item.id === id)
    if (!content) return null

    const image =
      content.relationships?.field_images?.localFile?.childImageSharp
        ?.gatsbyImageData

    return (
      <div className="space-y-5 bg-white py-5 lg:px-5 px-3">
        <p className="text-2xl font-bold text-center">{content.title}</p>
        {image && (
          <center>
            <GatsbyImage image={getImage(image)} alt={content.title} />
          </center>
        )}
        {content.body?.processed && (
          <p
            dangerouslySetInnerHTML={{ __html: content.body.processed }}
            className="text-justify custom-table"
          />
        )}
        {content.field_body?.processed && (
          <p
            dangerouslySetInnerHTML={{ __html: content.field_body.processed }}
            className="text-justify custom-table"
          />
        )}
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <Layout>
        <div className="lg:mx-20 mx-3 my-20">
          <Tabs
            selectedIndex={activeTab}
            onSelect={handleTabSelect}
            className="space-y-5"
          >
            <TabList className="text-white grid lg:grid-cols-3 grid-cols-1 gap-x-3 gap-y-3 text-center font-bold">
              {tabNames.map((tabName, index) => (
                <Tab
                  key={index}
                  className={`cursor-pointer bg-slate-900 py-2 hover:bg-white hover:text-black hover:border-b-4 hover:border-slate-900 ${
                    activeTab === index
                      ? "border-b-4 border-slate-900 bg-white text-black"
                      : ""
                  }`}
                >
                  {tabName}
                </Tab>
              ))}
            </TabList>
            {tabIds.map((id, index) => (
              <TabPanel key={index}>
                {activeTab === index && renderTabContent(id)}
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </Layout>
    </div>
  )
}

export default WhosWho
