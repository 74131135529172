import React, { useState } from "react"
import PageLayout from "../components/Page_layout"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

function GrievanceRedressal() {
  const [verify, setVerify] = useState(false)
  const [errormessage, setErrorMessage] = useState("")
  const [submitMessage, setSubmitMessage] = useState("")
  const [errorname, setErrorName] = useState("")
  const [erroremail, setErrorEmail] = useState("")
  const [errorsubject, setErrorSubject] = useState("")
  const [erroryourmessage, setErrorYourMessage] = useState("")

  const onChange = () => {
    setVerify(true)
  }

  const [formData, setFormData] = useState({
    your_name: "",
    your_email: "",
    subject: "",
    your_message: "",
  })

  const validateForm = () => {
    let validate = false

    if (formData.your_name.trim() === "") {
      setErrorName("Your name is required")
      validate = true
    } else if (!/^[a-zA-Z ]+$/.test(formData.your_name)) {
      setErrorName("Only alphabets and spaces are allowed")
      validate = true
    } else {
      setErrorName("")
    }

    if (formData.your_email.trim() === "") {
      setErrorEmail("Your email address is required")
      validate = true
    } else {
      setErrorEmail("")
    }

    if (formData.subject.trim() === "") {
      setErrorSubject("Your subject is required")
      validate = true
    } else {
      setErrorSubject("")
    }

    if (formData.your_message.trim() === "") {
      setErrorYourMessage("Your message is required")
      validate = true
    } else {
      setErrorYourMessage("")
    }

    return validate
  }

  const submitbutton = async event => {
    event.preventDefault()

    if (validateForm(event)) {
      return // Prevent form submission
    }

    try {
      const apiUrl = "https://be.megarise.in/webform_rest/submit?_format=json"

      const response = await axios.post(apiUrl, {
        webform_id: "complaints_grievance", // Add your webform ID here
        ...formData,
      })
      setSubmitMessage("Complaint form submitted successfully")
      setFormData({
        your_name: "",
        your_email: "",
        subject: "",
        your_message: "",
      }) // Reset the form data
      setTimeout(() => {
        setSubmitMessage("")
        setErrorMessage("")
      }, 5000)
      console.log("response", response.data)
    } catch (error) {
      setErrorMessage("Error submitting form: " + error.message)
      setTimeout(() => {
        setErrorMessage("")
      }, 5000)
      console.error("Error submitting form: " + error.message)
    }
  }

  const phoneNumber = "9233801587"
  const handleClick = () => {
    const whatsappUrl = `https://web.whatsapp.com/send/?phone=${phoneNumber}`
    window.open(whatsappUrl, "_blank")
  }

  const handleEmailClick = () => {
    const recipientEmail = "complianceofficer.megarise@gmail.com"
    const gmailUrl = `https://mail.google.com/mail/u/1/?view=cm&fs=1&to=${recipientEmail}`
    window.open(gmailUrl, "_blank")
  }

  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
    setErrorName("")
    setErrorEmail("")
    setErrorSubject("")
    setErrorYourMessage("")
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="border space-y-3 shadow mx-3 my-20 sm:mx-20 py-5 lg:px-0 px-3 rounded-3xl bg-white text-center">
          <p className="text-3xl">Grievance Redressal</p>
          <p className="text-3xl">
            Contact details of Nodal Person for Grievance Redressal
          </p>
          <p className="text-xl">
            Nodal Person - Sr. Manager, Social and Capacity building,
            Management, SPMU, MegARISE
          </p>
          <center className="space-y-3 text-white">
            <div
              onClick={handleClick}
              title="Open WhatsApp Web"
              className="text-lg bg-slate-500 hover:bg-slate-900 cursor-pointer px-2 py-1 rounded-lg lg:w-fit w-full"
            >
              <p className="flex gap-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="w-5"
                >
                  <path
                    fill="#ffffff"
                    d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                  />
                </svg>
                WhatsApp Number: 9233801587
              </p>
            </div>
            <div
              onClick={handleEmailClick}
              title="Open Gmail"
              className="text-lg bg-slate-500 hover:bg-slate-900 cursor-pointer px-2 py-1 rounded-lg lg:w-fit w-full"
            >
              <p className="flex gap-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-5"
                >
                  <path
                    fill="#ffffff"
                    d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"
                  />
                </svg>
                Email: complianceofficer.megarise@gmail.com
              </p>
            </div>
          </center>
        </div>
        <div className="mx-3 sm:mx-20 my-20 border shadow sm:px-10 px-3 lg:py-10 py-5 space-y-5 rounded-3xl bg-white">
          <p className="text-center text-2xl font-bold">Complaint Form</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-10">
            <div className="space-y-10">
              <div>
                <p className="font-bold text-2xl">MegARISE</p>
              </div>
              <div>
                <p className="font-bold">
                  Contact us to find out more or how we can help you better.
                </p>
              </div>
              <div>
                <p className="font-bold">General Enquiries</p>
                <p>For General Enquiries please use the form on the right.</p>
              </div>
              <div>
                <p className="font-bold">Address:</p>
                <p>
                  {" "}
                  RDL Building, Springside, Lumsohphoh, Jingkieng, Nongthymmai,
                  Shillong, Meghalaya 793014
                </p>
              </div>
              <div>
                <p className="font-bold">
                  Email ID:{" "}
                  <span className="font-normal">
                    complianceofficer.megarise@gmail.com
                  </span>
                </p>
              </div>
            </div>
            <div>
              <form
                className="space-y-5"
                onSubmit={submitbutton}
                autoComplete="off"
              >
                <div className="flex flex-col">
                  <label>
                    Your Name{" "}
                    <span className="text-red-500 font-extrabold">*</span>
                  </label>
                  <input
                    type="text"
                    name="your_name"
                    value={formData.your_name}
                    onChange={handleChange}
                    pattern="[a-zA-Z ]+"
                    className={`border-b-2 bg-gray-100 focus:border-black outline-none px-2 py-2 ${
                      errorname ? "border-red-500 focus:border-red-500" : ""
                    }`}
                  />
                  <p className="text-red-500">{errorname}</p>
                </div>
                <div className="flex flex-col">
                  <label>
                    Your Email Address{" "}
                    <span className="text-red-500 font-extrabold">*</span>
                  </label>
                  <input
                    type="email"
                    name="your_email"
                    value={formData.your_email}
                    onChange={handleChange}
                    inputMode="email"
                    className={`border-b-2 bg-gray-100 focus:border-black outline-none px-2 py-2 ${
                      erroremail ? "border-red-500 focus:border-red-500" : ""
                    }`}
                  />
                  <p className="text-red-500">{erroremail}</p>
                </div>
                <div className="flex flex-col">
                  <label>
                    Subject{" "}
                    <span className="text-red-500 font-extrabold">*</span>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className={`border-b-2 bg-gray-100 focus:border-black outline-none px-2 py-2 ${
                      errorsubject ? "border-red-500 focus:border-red-500" : ""
                    }`}
                  />
                  <p className="text-red-500">{errorsubject}</p>
                </div>
                <div className="flex flex-col">
                  <label>
                    Your Message{" "}
                    <span className="text-red-500 font-extrabold">*</span>
                  </label>
                  <textarea
                    className={`border-b-2 bg-gray-100 focus:border-black outline-none px-2 py-2 ${
                      erroryourmessage
                        ? "border-red-500 focus:border-red-500"
                        : ""
                    }`}
                    name="your_message"
                    value={formData.your_message}
                    onChange={handleChange}
                    rows="5"
                  />
                  <p className="text-red-500">{erroryourmessage}</p>
                </div>
                <center className="space-y-5">
                  <div>
                    <ReCAPTCHA
                      sitekey="6Lda-UYpAAAAABpwlAYij3omFx231qhzCjB6SvsE"
                      onChange={onChange}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={!verify}
                      className={`py-2 px-5 bg-slate-500 text-white rounded-full font-bold shadow ${
                        verify === true
                          ? "hover:bg-slate-900 cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                    >
                      Submit
                    </button>
                  </div>
                </center>
              </form>
              <p className="text-green-500 text-center font-bold">
                {submitMessage}
              </p>
              <p className="text-red-500 text-center font-bold">
                {errormessage}
              </p>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default GrievanceRedressal
