import { Link } from "gatsby"
import React from "react"

function ProjectCoverage() {
  return (
    <div className="space-y-3 relative lg:px-10 px-3 pb-5 bg-slate-100">
      <div className="flex absolute py-3 px-2 gap-x-5 lg:visible invisible">
        <p className="text-3xl font-bold custom-snow-blue">PROJECT COVERAGE</p>
        <Link
          to="/gis-dashboard"
          className="self-center text-white bg-[#007ac2] py-2 px-2 hover:bg-sky-800"
        >
          View Dashboard
        </Link>
      </div>
      <iframe
        width="100%"
        height="555px"
        src="https://meghalayacmdashboard.in/gisportal/apps/dashboards/441031dbb74b4f2e822e7c13174713af"
      ></iframe>
    </div>
  )
}

export default ProjectCoverage
