import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

function Logo() {
  return (
    <Link to="/" className="absolute z-10 left-5 lg:top-2 top-3 flex gap-x-3">
      <StaticImage
        src="../images/megarise3.png"
        class="w-20 rounded-full"
      />
      <div className="lg:text-3xl text-xl lg:pt-4 lg:self-start self-center text-white">
        <span>Meg</span>
        <span className="font-bold">ARISE</span>
      </div>
    </Link>
  )
}

export default Logo
