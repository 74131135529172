import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  faFilePdf,
  faCopy,
  faShare,
  faDownload,
  faLink,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PageLayout from "../components/Page_layout"

const Notification = ({ data, location }) => {
  const node = data.nodeNotification

  // Function to open WhatsApp share dialog
  const shareViaWhatsApp = () => {
    const shareUrl = location.href
    const title = node.title
    const date = node.changed
    const message = encodeURIComponent(
      `Check out this notice:\nTitle: *${title}*\nDate: ${date}\n*Click the link below:*\n${shareUrl}`
    )
    const whatsappUrl = `https://api.whatsapp.com/send?text=${message}`
    window.open(whatsappUrl, "_blank")
  }

  const copyURLToClipboard = () => {
    const url = window.location.href
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!")
      })
      .catch(error => {
        console.error("Error copying URL to clipboard:", error)
      })
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <Helmet>
          <meta property="og:title" content={node.title} />
          <meta
            property="og:description"
            content="Check out this notice link"
          />
          <meta property="og:url" content={location.href} />
        </Helmet>
        <div className="mx-3 sm:mx-20 my-20">
          <table className="custom-table bg-white">
            <tbody>
              <tr>
                <td>Date:</td>
                <td>{node.field_date}</td>
              </tr>
              <tr>
                <td>Title:</td>
                <td>{node.title}</td>
              </tr>
              {node.relationships.field_upload_pdf &&
              Array.isArray(node.relationships.field_upload_pdf) ? (
                node.relationships.field_upload_pdf.map((item, index) => (
                  <tr key={index}>
                    <td>File {index + 1}</td>
                    <td>
                      {item.title}
                      {item.localFile ? (
                        <div>
                          <a
                            href={item.localFile.publicURL}
                            className="btn btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              className="text-blue-500 text-2xl"
                            />{" "}
                            View File {index + 1}
                          </a>
                          {"\u00A0"} {"\u00A0"}
                          <a
                            href={item.localFile.publicURL}
                            download
                            className="btn btn-secondary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="text-blue-500 text-2xl pr-2"
                            />
                            Download File {index + 1}
                          </a>
                        </div>
                      ) : (
                        <p>PDF not available</p>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                // Handle case where PDF data is not available
                <tr>
                  <td>PDF File</td>
                  <td>{node.relationships.field_upload_pdf.field_date}</td>
                  <td>
                    {node.relationships.field_upload_pdf.title}
                    {node.relationships.field_upload_pdf.localFile ? (
                      <div>
                        <a
                          href={
                            node.relationships.field_upload_pdf.localFile
                              .publicURL
                          }
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View File
                        </a>
                        {"\u00A0"} {"\u00A0"}
                        <a
                          href={
                            node.relationships.field_upload_pdf.localFile
                              .publicURL
                          }
                          download
                          className="btn btn-secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download File
                        </a>
                      </div>
                    ) : (
                      <p>PDF not available</p>
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <td>Link URL</td>
                <td>
                  {node.field_link_url.map((mylink, index) => (
                    <div key={index}>
                      <a href={mylink.uri} className="">
                        <FontAwesomeIcon
                          icon={faLink}
                          className="text-blue-600 semi-bold text-xl px-2"
                        />{" "}
                        {mylink.title}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
              {/* ... (Render other data in the table if required) */}
            </tbody>
          </table>
          <div className="flex justify-end gap-x-5 mt-3">
            <button
              className="px-2 py-1 bg-sky-800 text-white"
              onClick={shareViaWhatsApp}
            >
              Share <FontAwesomeIcon icon={faShare} />
            </button>
            <button
              className="px-2 py-1 bg-sky-800 text-white"
              onClick={copyURLToClipboard}
            >
              Copy URL <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export const query = graphql`
  query ($alias: String!) {
    nodeNotification(path: { alias: { eq: $alias } }) {
      title
      path {
        alias
      }
      field_link_url {
        title
        uri
      }
      field_date(formatString: "DD-MM-YYYY")
      relationships {
        field_upload_pdf {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default Notification
