import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressCard } from "@fortawesome/free-regular-svg-icons"
import { faLink, faPager } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import AnalyticsDashboard from "./AnalyticsDashboard"

function Footer() {
  const phoneNumber = "9233801587"
  const handleClick = () => {
    const whatsappUrl = `https://web.whatsapp.com/send/?phone=${phoneNumber}`
    window.open(whatsappUrl, "_blank")
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-10 lg:px-20 px-5 py-10 bg-[#252529]">
      <div className="flex flex-col justify-between gap-y-28">
        <div className="space-y-5">
          <h2 className="font-semibold text-xl text-white border-b w-fit">
            <FontAwesomeIcon icon={faPager} className="pr-1" />
            Who We Are
          </h2>
          <ul className="list-none leading-8">
            <li className="hover:text-blue-500 text-white">
              <Link to="/about-megarise">About MegARISE</Link>
            </li>
            <li className="hover:text-blue-500 text-white">
              <Link to="/who's-who">Who's Who</Link>
            </li>
          </ul>
        </div>
        <AnalyticsDashboard />
      </div>
      <div className="space-y-5">
        <h2 className="font-semibold text-xl text-white border-b w-fit">
          <FontAwesomeIcon icon={faLink} className="pr-1" />
          Quick Link
        </h2>
        <ul className="text-white list-none leading-8">
          <li className="hover:text-blue-500 text-white">
            <Link to="/photo-gallery">Photo Gallery</Link>
          </li>
          <li className="hover:text-blue-500 text-white">
            <Link to="/terms-and-condition">Terms and Condition</Link>
          </li>
          <li className="hover:text-blue-500 text-white">
            <Link to="/website-policy">Website Policy</Link>
          </li>
          <li className="hover:text-blue-500 text-white">
            <Link to="/accessibility-statement">Accessibility Statement</Link>
          </li>
          <li className="hover:text-blue-500 text-white">
            <Link to="/disclaimer">Disclaimer</Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col justify-between gap-y-28">
        <div className="space-y-5">
          <h2 className="font-semibold text-xl text-white border-b w-fit">
            <FontAwesomeIcon icon={faAddressCard} className="pr-1" />
            Address
          </h2>
          <ul className="text-white list-none">
            <li className="hover:text-blue-500 text-white leading-8">
              RDL Building, Springside, Lumsohphoh, Jingkieng, Nongthymmai,
              Shillong, Meghalaya 793014
            </li>
          </ul>
        </div>
        <div className="flex gap-x-5">
          <Link
            to="https://www.instagram.com/mbda_shillong/?igsh=MXdhbTJiZWJ0ODdtMg%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-8"
            >
              <path
                fill="#ffffff"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </Link>
          <Link
            to="https://www.facebook.com/mbdashillong/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-8"
            >
              <path
                fill="#ffffff"
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"
              />
            </svg>
          </Link>
          <Link
            to="https://www.youtube.com/@meghalayabasindevelopmenta6058"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-8"
            >
              <path
                fill="#ffffff"
                d="M282 256.2l-95.2-54.1V310.3L282 256.2zM384 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zm14.4 136.1c7.6 28.6 7.6 88.2 7.6 88.2s0 59.6-7.6 88.1c-4.2 15.8-16.5 27.7-32.2 31.9C337.9 384 224 384 224 384s-113.9 0-142.2-7.6c-15.7-4.2-28-16.1-32.2-31.9C42 315.9 42 256.3 42 256.3s0-59.7 7.6-88.2c4.2-15.8 16.5-28.2 32.2-32.4C110.1 128 224 128 224 128s113.9 0 142.2 7.7c15.7 4.2 28 16.6 32.2 32.4z"
              />
            </svg>
          </Link>
          <div onClick={handleClick} className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-8"
            >
              <path
                fill="#ffffff"
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
