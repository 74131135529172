import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faMultiply } from "@fortawesome/free-solid-svg-icons"
import "./nav.css"
import { useLocation } from "@reach/router"

function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const [about, setAbout] = useState(false)
  const [village, setVillage] = useState(false)
  const [explore, setExplore] = useState(false)
  const [photo, setPhoto] = useState(false)
  const [capacity, setCapacity] = useState(false)
  const [advertise, setAdvertise] = useState(false)
  const [contact, setContact] = useState(false)
  const [activenavbar, setActiveNavbar] = useState("")
  const location = useLocation()

  useEffect(() => {
    setActiveNavbar(location.pathname)
  }, [location.pathname])

  const enterAbout = () => {
    setAbout(true)
  }
  const leaveAbout = () => {
    setAbout(false)
  }

  const enterVillage = () => {
    setVillage(true)
  }
  const leaveVillage = () => {
    setVillage(false)
  }

  const enterExplore = () => {
    setExplore(true)
  }
  const leaveExplore = () => {
    setExplore(false)
  }

  const enterPhoto = () => {
    setPhoto(true)
  }
  const leavePhoto = () => {
    setPhoto(false)
  }

  const enterCapacity = () => {
    setCapacity(true)
  }
  const leaveCapacity = () => {
    setCapacity(false)
  }

  const enterAdvertise = () => {
    setAdvertise(true)
  }
  const leaveAdvertise = () => {
    setAdvertise(false)
  }

  const enterContact = () => {
    setContact(true)
  }
  const leaveContact = () => {
    setContact(false)
  }

  return (
    <nav className="flex flex-wrap justify-between lg:px-10 px-5 py-5 bg-[#252529] w-full text-white">
      <div className="block lg:hidden ml-auto">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center py-2"
        >
          <div className={`fill-current ${isOpen ? "hidden" : "block"}`}>
            <FontAwesomeIcon icon={faBars} className="h-10 w-10" />
          </div>
          <div className={`fill-current ${isOpen ? "block" : "hidden"}`}>
            <FontAwesomeIcon icon={faMultiply} className="h-10 w-10" />
          </div>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="flex lg:flex-row flex-col gap-x-20 z-10 justify-end ml-auto lg:py-0 py-5">
          <Link
            to="/"
            activeClassName="activemenu"
            className="py-3 lg:text-center lg:transition-transform lg:duration-300 lg:hover:scale-125 hover:font-bold"
          >
            HOME
          </Link>
          <div
            className="hover:font-bold"
            onMouseEnter={enterAbout}
            onMouseLeave={leaveAbout}
            onClick={() => setAbout(prev => !prev)}
          >
            <div
              className={`py-3 cursor-pointer lg:transition-transform lg:duration-300 lg:hover:scale-125 ${
                activenavbar.includes("/ganol-catchment-unit")
                  ? "activemenu"
                  : ""
              } ${
                activenavbar.includes("/umiew-catchment-unit")
                  ? "activemenu"
                  : ""
              } ${
                activenavbar.includes("/about-megarise") ? "activemenu" : ""
              } ${activenavbar.includes("/who's-who") ? "activemenu" : ""} ${
                activenavbar.includes("/implementation-structure")
                  ? "activemenu"
                  : ""
              } ${
                activenavbar.includes("/villages-under-megarise")
                  ? "activemenu"
                  : ""
              }`}
            >
              WHO WE ARE
              <center>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={`w-3 ${
                    about === true ? "rotate-180 font-bold" : ""
                  }`}
                >
                  <path
                    fill={
                      activenavbar.includes("/about-megarise") ||
                      activenavbar.includes("/implementation-structure") ||
                      activenavbar.includes("/villages-under-megarise") ||
                      activenavbar.includes("/who's-who") ||
                      activenavbar.includes("/ganol-catchment-unit") ||
                      activenavbar.includes("/umiew-catchment-unit") ||
                      about === true
                        ? "#ffffff"
                        : "#ffffff"
                    }
                    stroke="#ffffff" // Set the stroke color
                    strokeWidth="100"
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </center>
            </div>
            <div className="sm:absolute relative">
              {about && (
                <ul className="bg-[#252529]   text-white font-normal">
                  <li className="">
                    {" "}
                    <Link
                      to="/about-megarise"
                      activeClassName="activemenu"
                      className="py-2 px-5 block hover:font-bold"
                    >
                      About MegARISE
                    </Link>
                  </li>
                  <hr />
                  <li
                    className="hover:font-bold"
                    onMouseEnter={enterVillage}
                    onMouseLeave={leaveVillage}
                    onClick={e => {
                      e.stopPropagation() // Prevent closing "Explore"
                      setVillage(prev => !prev) // Toggle Photo Gallery
                    }}
                  >
                    <Link
                      to="/villages-under-megarise"
                      onClick={e => e.preventDefault()}
                      activeClassName="activemenu"
                      className={`py-2 px-5 flex justify-between cursor-pointer ${
                        activenavbar.includes("/ganol-catchment-unit")
                          ? "activemenu"
                          : ""
                      } ${
                        activenavbar.includes("/umiew-catchment-unit")
                          ? "activemenu"
                          : ""
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-3 lg:-rotate-90"
                      >
                        <path
                          fill={
                            activenavbar.includes("/ganol-catchment-unit") ||
                            activenavbar.includes("/umiew-catchment-unit") ||
                            village === true
                              ? "#ffffff"
                              : "#ffffff"
                          }
                          stroke="#ffffff" // Set the stroke color
                          strokeWidth="100"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                      <span className="order-first">
                        Villages under MegARISE
                      </span>
                    </Link>
                    {village && (
                      <ul className="bg-[#252529]   text-white font-normal lg:absolute relative top-[41px] lg:left-[260px] w-full lg:w-[211px]">
                        <li className="">
                          <Link
                            to="/ganol-catchment-unit"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Ganol Catchment Unit
                          </Link>
                        </li>
                        <hr />
                        <li className="">
                          {" "}
                          <Link
                            to="/umiew-catchment-unit"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Umiew Catchment Unit
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <hr />
                  <li className="">
                    {" "}
                    <Link
                      to="/who's-who"
                      activeClassName="activemenu"
                      className="py-2 px-5 block hover:font-bold"
                    >
                      Who's Who
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to="/implementation-structure"
                      activeClassName="activemenu"
                      className="py-2 px-5 block hover:font-bold"
                    >
                      Implementation Structure
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div
            className="hover:font-bold"
            onMouseEnter={enterExplore}
            onMouseLeave={leaveExplore}
            onClick={() => setExplore(prev => !prev)}
          >
            <div
              className={`py-3 cursor-pointer lg:transition-transform lg:duration-300 lg:hover:scale-125 ${
                activenavbar.includes("/photos-ganol-catchment")
                  ? "activemenu"
                  : ""
              } ${
                activenavbar.includes("/photos-umiew-catchment")
                  ? "activemenu"
                  : ""
              } ${activenavbar.includes("/more-photos") ? "activemenu" : ""} ${
                activenavbar.includes("/project-documents") ? "activemenu" : ""
              } ${
                activenavbar.includes("/training-materials") ? "activemenu" : ""
              } ${
                activenavbar.includes("/photo-gallery") ? "activemenu" : ""
              } ${
                activenavbar.includes("/mbda-mbma-newsletter")
                  ? "activemenu"
                  : ""
              }`}
            >
              EXPLORE
              <center>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={`w-3 ${explore === true ? "rotate-180" : ""}`}
                >
                  <path
                    fill={
                      activenavbar.includes("/photos-ganol-catchment") ||
                      activenavbar.includes("/photos-umiew-catchment") ||
                      activenavbar.includes("/more-photos") ||
                      activenavbar.includes("/project-documents") ||
                      activenavbar.includes("/training-materials") ||
                      activenavbar.includes("/photo-gallery") ||
                      activenavbar.includes("/update-of-the-week") ||
                      activenavbar.includes("/mbda-mbma-newsletter") ||
                      explore === true
                        ? "#ffffff"
                        : "#ffffff"
                    }
                    stroke="#ffffff" // Set the stroke color
                    strokeWidth="100"
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </center>
            </div>
            <div className="sm:absolute relative">
              {explore && (
                <ul className="bg-[#252529]   text-white font-normal">
                  <li
                    className="hover:font-bold"
                    onMouseEnter={enterPhoto}
                    onMouseLeave={leavePhoto}
                    onClick={e => {
                      e.stopPropagation() // Prevent closing "Explore"
                      setPhoto(prev => !prev) // Toggle Photo Gallery
                    }}
                  >
                    <Link
                      to="/photo-gallery"
                      onClick={e => e.preventDefault()}
                      activeClassName="activemenu"
                      className={`py-2 px-5 flex justify-between cursor-pointer ${
                        activenavbar.includes("/photos-ganol-catchment")
                          ? "activemenu"
                          : ""
                      } ${
                        activenavbar.includes("/photos-umiew-catchment")
                          ? "activemenu"
                          : ""
                      } ${
                        activenavbar.includes("/more-photos")
                          ? "activemenu"
                          : ""
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-3 lg:-rotate-90"
                      >
                        <path
                          fill={
                            activenavbar.includes("/photos-ganol-catchment") ||
                            activenavbar.includes("/photos-umiew-catchment") ||
                            activenavbar.includes("/more-photos") ||
                            photo === true
                              ? "#ffffff"
                              : "#ffffff"
                          }
                          stroke="#ffffff" // Set the stroke color
                          strokeWidth="100"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                      <span className="order-first">Photo Gallery</span>
                    </Link>
                    {photo && (
                      <ul className="bg-[#252529]   text-white font-normal lg:absolute relative top-0 lg:left-[239px] w-full lg:w-[255px]">
                        <li className="">
                          <Link
                            to="/photos-ganol-catchment"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Photos of Ganol Catchment
                          </Link>
                        </li>
                        <hr />
                        <li className="">
                          {" "}
                          <Link
                            to="/photos-umiew-catchment"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Photos of Umiew Catchment
                          </Link>
                        </li>
                        <hr />
                        <li className="">
                          {" "}
                          <Link
                            to="/more-photos"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            More Photos
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <hr />
                  <li
                    className="hover:font-bold"
                    onMouseEnter={enterCapacity}
                    onMouseLeave={leaveCapacity}
                    onClick={e => {
                      e.stopPropagation() // Prevent closing "Explore"
                      setCapacity(prev => !prev) // Toggle Photo Gallery
                    }}
                  >
                    <Link
                      to="/capacity-building"
                      onClick={e => e.preventDefault()}
                      activeClassName="activemenu"
                      className={`py-2 px-5 flex justify-between cursor-pointer ${
                        activenavbar.includes("/training-materials")
                          ? "activemenu"
                          : ""
                      } ${
                        activenavbar.includes("/iec-materials")
                          ? "activemenu"
                          : ""
                      } ${
                        activenavbar.includes("/reels-videos")
                          ? "activemenu"
                          : ""
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-3 lg:-rotate-90"
                      >
                        <path
                          fill={
                            activenavbar.includes("/training-materials") ||
                            activenavbar.includes("/iec-materials") ||
                            activenavbar.includes("/reels-videos") ||
                            capacity === true
                              ? "#ffffff"
                              : "#ffffff"
                          }
                          stroke="#ffffff" // Set the stroke color
                          strokeWidth="100"
                          d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                        />
                      </svg>
                      <span className="order-first">Capacity Building</span>
                    </Link>
                    {capacity && (
                      <ul className="bg-[#252529]   text-white font-normal lg:absolute relative top-[41px] lg:left-[239px] w-full lg:w-[233px]">
                        <li className="">
                          <Link
                            to="/training-materials"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Training Materials
                          </Link>
                        </li>
                        <hr />
                        <li className="">
                          {" "}
                          <Link
                            to="/iec-materials"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            IEC Materials
                          </Link>
                        </li>
                        <hr />
                        <li className="">
                          {" "}
                          <Link
                            to="/reels-videos"
                            activeClassName="activemenu"
                            className="hover:font-bold py-2 lg:px-2 px-10 block"
                          >
                            Reels/Videos
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to="/mbda-mbma-newsletter"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      MBDA/MBMA Newsletter
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div
            className="hover:font-bold"
            onMouseEnter={enterAdvertise}
            onMouseLeave={leaveAdvertise}
            onClick={() => setAdvertise(!advertise)}
          >
            <div
              className={`py-3 cursor-pointer lg:transition-transform lg:duration-300 lg:hover:scale-125 ${
                activenavbar.includes("/notifications") ? "activemenu" : ""
              } ${activenavbar.includes("/tender") ? "activemenu" : ""} ${
                activenavbar.includes("/recruitment") ? "activemenu" : ""
              }`}
            >
              NOTIFICATIONS
              <center>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={`w-3 ${advertise === true ? "rotate-180" : ""}`}
                >
                  <path
                    fill={
                      activenavbar.includes("/notifications") ||
                      activenavbar.includes("/tender") ||
                      activenavbar.includes("/recruitment") ||
                      advertise === true
                        ? "#ffffff"
                        : "#ffffff"
                    }
                    stroke="#ffffff" // Set the stroke color
                    strokeWidth="100"
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </center>
            </div>
            {advertise && (
              <div className="sm:absolute relative">
                <ul className="bg-[#252529]   text-white font-normal">
                  <li className="">
                    <Link
                      to="/recruitment"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      Recruitment
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    {" "}
                    <Link
                      to="/notifications"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      Notifications
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    <Link
                      to="/tender"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      Tenders & Award of Contract
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div
            className="hover:font-bold"
            onMouseEnter={enterContact}
            onMouseLeave={leaveContact}
            onClick={() => setContact(!contact)}
          >
            <div
              className={`py-3 cursor-pointer lg:transition-transform lg:duration-300 lg:hover:scale-125 ${
                activenavbar.includes("/complaint-grievance")
                  ? "activemenu"
                  : ""
              } ${
                activenavbar.includes("/procurement-complaint")
                  ? "activemenu"
                  : ""
              }`}
            >
              GRIEVANCE
              <center>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className={`w-3 ${contact === true ? "rotate-180" : ""}`}
                >
                  <path
                    fill={
                      activenavbar.includes("/complaint-grievance") ||
                      activenavbar.includes("/procurement-complaint") ||
                      contact === true
                        ? "#ffffff"
                        : "#ffffff"
                    }
                    stroke="#ffffff" // Set the stroke color
                    strokeWidth="100"
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                  />
                </svg>
              </center>
            </div>
            {contact && (
              <div className="sm:absolute relative">
                <ul className="bg-[#252529]   text-white font-normal">
                  <li className="">
                    <Link
                      to="/procurement-complaint"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      Procurement Complaints
                    </Link>
                  </li>
                  <hr />
                  <li className="">
                    {" "}
                    <Link
                      to="/complaint-grievance"
                      activeClassName="activemenu"
                      className="hover:font-bold py-2 px-5 block"
                    >
                      Complaints and Grievance
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <Link
            to="/gis-dashboard"
            activeClassName="activemenu"
            className="py-3 lg:text-center lg:transition-transform lg:duration-300 lg:hover:scale-125 hover:font-bold"
          >
            GIS DASHBOARD
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
