import React, { useRef, useState } from "react"
import PageLayout from "../components/Page_layout"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

function ProcurementComplaint() {
  const [verify, setVerify] = useState(false)
  const [errormessage, setErrorMessage] = useState("")
  const [submitMessage, setSubmitMessage] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [uploadedFilePath, setUploadedFilePath] = useState("")
  const [errorcomplinant, setErrorComplinant] = useState("")
  const [errorcompany, setErrorCompany] = useState("")
  const [erroraddress, setErrorAddress] = useState("")
  const [erroremail, setErrorEmail] = useState("")
  const [errorphone, setErrorPhone] = useState("")
  const [errorcategory, setErrorCategory] = useState("")
  const [errorbidtitle, setErrorBidTitle] = useState("")
  const [errorpublishingdate, setErrorPublishingDate] = useState("")
  const [errorclosingdate, setErrorClosingDate] = useState("")
  const [errordescription, setErrorDescription] = useState("")
  const [
    errorprocurementcomplaintcategory,
    setErrorProcurementComplaintCategory,
  ] = useState("")

  const [checkedcategory, setCheckedCategory] = useState([])
  const checkboxRefs = useRef({})
  const fileInputRef = useRef(null)

  const [formData, setFormData] = useState({
    name_of_complaint: "",
    company_name: "",
    company_address: "",
    complaint_email_id: "",
    complaint_phone_number: "",
    procurement_category: "",
    bid_reference_title: "",
    bid_publishing_date: "",
    bid_closing_date: "",
    description: "",
    procurement_complaint_category: [],
    document: "",
  })

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0])
  }

  const onChange = () => {
    setVerify(true)
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload.")
      return null
    }

    const fileData = new FormData()
    fileData.append("file", selectedFile)

    try {
      const response = await axios.post(
        "https://be.megarise.in/upload.php",
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (response.data.status === "success") {
        const filePath = `https://be.megarise.in/${response.data.filePath}`
        const encodedFilePath = encodeURI(filePath) // Encode the file path
        setUploadedFilePath(encodedFilePath)

        setFormData(prevData => ({
          ...prevData,
          document: encodedFilePath,
        }))
        return encodedFilePath
      } else {
        setErrorMessage("File upload failed. Please try again.")
        return null
      }
    } catch (error) {
      setErrorMessage("File upload failed. Please try again.")
      return null
    }
  }

  const validateForm = () => {
    let isValid = false

    if (formData.name_of_complaint.trim() === "") {
      setErrorComplinant("Name of complaint is required.")
      isValid = true
    } else if (!/^[a-zA-Z ]+$/.test(formData.name_of_complaint)) {
      setErrorComplinant("Invalid name of complaint.")
      isValid = true
    } else {
      setErrorComplinant("")
    }

    if (formData.company_name.trim() === "") {
      setErrorCompany("Company name is required.")
      isValid = true
    } else {
      setErrorCompany("")
    }

    if (formData.company_address.trim() === "") {
      setErrorAddress("Company address is required.")
      isValid = true
    } else {
      setErrorAddress("")
    }

    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        formData.complaint_email_id
      )
    ) {
      setErrorEmail("Invalid email address.")
      isValid = true
    } else if (formData.complaint_email_id.trim() === "") {
      setErrorEmail("Email address is required.")
      isValid = true
    } else {
      setErrorEmail("")
    }

    if (!/^[0-9]{10}$/.test(formData.complaint_phone_number)) {
      setErrorPhone("Invalid phone number.")
      isValid = true
    } else if (formData.complaint_phone_number.trim() === "") {
      setErrorPhone("Phone number is required.")
      isValid = true
    } else {
      setErrorPhone("")
    }

    if (!formData.procurement_category) {
      setErrorCategory("Procurement category is required.")
      isValid = true
    } else {
      setErrorCategory("")
    }

    if (formData.bid_reference_title.trim() === "") {
      setErrorBidTitle("Bid reference title is required.")
      isValid = true
    } else {
      setErrorBidTitle("")
    }

    if (formData.bid_publishing_date.trim() === "") {
      setErrorPublishingDate("Bid publishing date is required.")
      isValid = true
    } else {
      setErrorPublishingDate("")
    }

    if (formData.bid_closing_date.trim() === "") {
      setErrorClosingDate("Bid closing date is required.")
      isValid = true
    } else {
      setErrorClosingDate("")
    }

    if (formData.procurement_complaint_category.length === 0) {
      setErrorProcurementComplaintCategory(
        "Procurement complaint category are required."
      )
      isValid = true
    } else {
      setErrorProcurementComplaintCategory("")
    }

    if (formData.description.trim() === "") {
      setErrorDescription("Description is required.")
      isValid = true
    } else {
      setErrorDescription("")
    }

    return isValid
  }

  const submitbutton = async event => {
    event.preventDefault()

    if (validateForm(event)) {
      return // Prevent form submission
    }

    if (!selectedFile) {
      alert("Please select a file to upload.")
      return
    }

    const filePath = await handleFileUpload()

    if (!filePath) {
      return // Exit if file upload failed
    }
    const finalFormData = {
      ...formData,
      document: filePath, // Ensure document is updated
      procurement_complaint_category: formData.procurement_complaint_category, // Include the category array
    }

    try {
      const apiUrl = "https://be.megarise.in/webform_rest/submit?_format=json"

      // Ensure the payload matches what the API expects
      const response = await axios.post(apiUrl, {
        webform_id: "procurement_complaints", // Add your webform ID here
        ...finalFormData, // Use finalFormData here
      })

      setSubmitMessage("Complaint form submitted successfully")
      setFormData({
        name_of_complaint: "",
        company_name: "",
        company_address: "",
        complaint_email_id: "",
        complaint_phone_number: "",
        procurement_category: "",
        bid_reference_title: "",
        bid_publishing_date: "",
        bid_closing_date: "",
        description: "",
        procurement_complaint_category: [], // Reset the category array
        document: "",
      })
      setSelectedFile(null)
      setUploadedFilePath("")
      setCheckedCategory([])
      Object.values(checkboxRefs.current).forEach(checkbox => {
        if (checkbox) {
          checkbox.checked = false
        }
      })
      checkboxRefs.current = {}
      if (fileInputRef.current) {
        fileInputRef.current.value = "" // Clear the file input
      } // Reset the checkbox references
      setTimeout(() => {
        setSubmitMessage("")
        setErrorMessage("")
      }, 5000)
    } catch (error) {
      setErrorMessage(
        "There was an error submitting the form. Please try again."
      )
      setTimeout(() => {
        setErrorMessage("")
      }, 5000)
    }
  }

  const handleCheckboxChange = event => {
    const { value, checked } = event.target
    if (checked) {
      setCheckedCategory(prev => [...prev, value])
    } else {
      setCheckedCategory(prev => prev.filter(val => val !== value))
    }
    setFormData(prevState => ({
      ...prevState,
      procurement_complaint_category: checked
        ? [...(prevState.procurement_complaint_category || []), value]
        : (prevState.procurement_complaint_category || []).filter(
            option => option !== value
          ),
    }))
    setErrorProcurementComplaintCategory("")
  }

  const handleRemove = value => {
    setCheckedCategory(prev => prev.filter(val => val !== value)) // Remove the value
    setFormData(prevState => ({
      ...prevState,
      procurement_complaint_category:
        prevState.procurement_complaint_category.filter(
          category => category !== value
        ),
    }))
    if (checkboxRefs.current[value]) {
      checkboxRefs.current[value].checked = false
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
    if (name === "name_of_complaint") setErrorComplinant("")
    if (name === "company_name") setErrorCompany("")
    if (name === "company_address") setErrorAddress("")
    if (name === "complaint_email_id") setErrorEmail("")
    if (name === "complaint_phone_number") setErrorPhone("")
    if (name === "procurement_category") setErrorCategory("")
    if (name === "bid_reference_title") setErrorBidTitle("")
    if (name === "bid_publishing_date") setErrorPublishingDate("")
    if (name === "bid_closing_date") setErrorClosingDate("")
    if (name === "description") setErrorDescription("")
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 my-20 sm:mx-20 py-5 sm:px-5 px-3 border shadow space-y-10 rounded-3xl bg-white">
          <div className="space-y-5">
            <p className="text-2xl text-center font-bold">
              Procurement Complaint
            </p>
            <div className="text-center space-y-3">
              <p>
                <span className="text-red-500 font-extrabold">*</span>
                <span>
                  Please note that this complaint submission form is intended
                  for procurement-related complaints only
                </span>
              </p>
              <p>
                For Procurement Complaint Handling Protocol will be available
                soon
              </p>
              <p>
                or send an e-mail to kfwmbdaprocurement@gmail.com to register
                your complains via e-mail.
              </p>
            </div>
          </div>
          <form
            id="complaintForm"
            className="space-y-10"
            onSubmit={submitbutton}
            autoComplete="off"
          >
            <div className="space-y-5 border border-gray-500 py-5 sm:px-5 px-3 rounded-3xl">
              <p className="text-center text-xl">Basic Complaint</p>
              <div className="flex flex-col">
                <label>
                  Name of Complainant{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="text"
                  name="name_of_complaint"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.name_of_complaint}
                  onChange={handleChange}
                  pattern="[a-zA-Z ]+"
                />
                <p className="text-red-500">{errorcomplinant}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Company Name{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="text"
                  name="company_name"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.company_name}
                  onChange={handleChange}
                />
                <p className="text-red-500">{errorcompany}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Company Address{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <textarea
                  name="company_address"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  rows="5"
                  value={formData.company_address}
                  onChange={handleChange}
                ></textarea>
                <p className="text-red-500">{erroraddress}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Complainant E-Mail ID{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="email"
                  name="complaint_email_id"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.complaint_email_id}
                  onChange={handleChange}
                  inputMode="email"
                />
                <p className="text-red-500">{erroremail}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Complainant Phone Number{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="text"
                  name="complaint_phone_number"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.complaint_phone_number}
                  onChange={handleChange}
                  maxLength="10"
                  pattern="[0-9]{10}"
                  inputMode="tel"
                />
                <p className="text-red-500">{errorphone}</p>
              </div>
            </div>
            <div className="space-y-5 border border-gray-500 py-5 sm:px-5 px-3 rounded-3xl">
              <p className="text-center text-xl">Tender Details</p>
              <div className="flex flex-col">
                <label>
                  Procurement Category{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <select
                  name="procurement_category"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.procurement_category}
                  onChange={handleChange}
                >
                  <option value="">Select Procurement Category</option>
                  <option value="Goods">Goods</option>
                  <option value="Consultancy Services">
                    Consultancy Services
                  </option>
                  <option value="Non Consultancy Services">
                    Non Consultancy Services
                  </option>
                  <option value="Works">Works</option>
                </select>
                <p className="text-red-500">{errorcategory}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Bid Reference Title{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="text"
                  name="bid_reference_title"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.bid_reference_title}
                  onChange={handleChange}
                  pattern="[a-zA-Z ]+"
                />
                <p className="text-red-500">{errorbidtitle}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Bid Publishing Date{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="date"
                  name="bid_publishing_date"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.bid_publishing_date}
                  onChange={handleChange}
                />
                <p className="text-red-500">{errorpublishingdate}</p>
              </div>
              <div className="flex flex-col">
                <label>
                  Bid Closing Date{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <input
                  type="date"
                  name="bid_closing_date"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  value={formData.bid_closing_date}
                  onChange={handleChange}
                />
                <p className="text-red-500">{errorclosingdate}</p>
              </div>
            </div>
            <div className="space-y-5 border border-gray-500 py-5 sm:px-5 px-3 rounded-3xl">
              <p className="text-center text-xl">Procurement Complaint</p>
              <div className="flex flex-col">
                <div>
                  <div>
                    Procurement Complaint Category{" "}
                    <span className="text-red-500 font-extrabold">*</span>
                  </div>
                  <div className="lg:px-5 px-3 py-5 border border-gray-300 rounded-xl grid lg:grid-cols-2 grid-cols-1 gap-y-3">
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Bid publishing/advertisement"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current[
                            "Bid publishing/advertisement"
                          ] = el)
                        }
                      />
                      <label htmlFor="Bid publishing/advertisement">
                        Bid publishing/advertisement
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Bidding procedure"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current["Bidding procedure"] = el)
                        }
                      />
                      <label htmlFor="Bidding procedure">
                        Bidding procedure
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Bid preparation and submission"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current[
                            "Bid preparation and submission"
                          ] = el)
                        }
                      />
                      <label htmlFor="Bid preparation and submission">
                        Bid preparation and submission
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Pre-qualification/inital selection"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current[
                            "Pre-qualification/inital selection"
                          ] = el)
                        }
                      />
                      <label htmlFor="Pre-qualification/inital selection">
                        Pre-qualification/inital selection
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Technical evaluation"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current["Technical evaluation"] = el)
                        }
                      />
                      <label htmlFor="Technical evaluation">
                        Technical evaluation
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Financial evaluation"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current["Financial evaluation"] = el)
                        }
                      />
                      <label htmlFor="Financial evaluation">
                        Financial evaluation
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Award of contract"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current["Award of contract"] = el)
                        }
                      />
                      <label htmlFor="Award of contract">
                        Award of contract
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Contract management"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current["Contract management"] = el)
                        }
                      />
                      <label htmlFor="Contract management">
                        Contract management
                      </label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Payments"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el => (checkboxRefs.current["Payments"] = el)}
                      />
                      <label htmlFor="Payments">Payments</label>
                    </div>
                    <div className="space-x-1 flex">
                      <input
                        type="checkbox"
                        value="Anticorruption and procurement integrity issues"
                        onChange={handleCheckboxChange}
                        className="larger self-center"
                        ref={el =>
                          (checkboxRefs.current[
                            "Anticorruption and procurement integrity issues"
                          ] = el)
                        }
                      />
                      <label htmlFor="Anticorruption and procurement integrity issues">
                        Anticorruption and procurement integrity issues
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-red-500">
                  {errorprocurementcomplaintcategory}
                </p>
              </div>
              <div className="flex flex-col">
                <label>
                  Description{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </label>
                <textarea
                  name="description"
                  className="border border-gray-300 py-3 pl-3 rounded-xl"
                  rows="5"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
                <p className="text-red-500">{errordescription}</p>
              </div>
              <div className="flex flex-col">
                <p>
                  Upload File{" "}
                  <span className="text-red-500 font-extrabold">*</span>
                </p>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="border border-gray-300 border-dashed w-full file:py-3 file:border-none rounded-xl"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <ReCAPTCHA
                sitekey="6Lda-UYpAAAAABpwlAYij3omFx231qhzCjB6SvsE"
                onChange={onChange}
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                disabled={!verify}
                className={`bg-slate-500 text-white py-2 px-5 rounded-full font-bold ${
                  verify === true
                    ? "hover:bg-slate-900 cursor-pointer"
                    : "cursor-not-allowed"
                }`}
              >
                Submit
              </button>
            </div>
            {submitMessage && (
              <div className="text-center text-green-500">{submitMessage}</div>
            )}
            {errormessage && (
              <div className="text-center text-red-500">{errormessage}</div>
            )}
          </form>
        </div>
      </PageLayout>
    </div>
  )
}

export default ProcurementComplaint
