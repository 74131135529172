import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import Layout from "../components/Page_layout.js"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import FPA from "../components/fpa-umiew.js"
import PRA from "../components/pra-umiew.js"
import Awareness from "../components/awareness-umiew.js"

function PhotoUCMU() {
  const [activeTab, setActiveTab] = useState(0)
  const [tabs, setTabs] = useState("Awareness") // default tab
  const tabNames = [
    "Awareness",
    "Participatory Rural Appraisal",
    "Financial Participatory Approach",
    "Others",
  ]
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const imagesPerPage = 9
  const [modalOpen, setModalOpen] = useState(true)
  const [colorsvg, setColorSVG] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      allNodePhotoGallery(
        filter: {
          field_catchment_units: { eq: "umiew" }
          title: {
            in: [
              "Awareness"
              "Participatory Rural Appraisal"
              "Financial Participatory Approach"
              "Others"
            ]
          }
        }
      ) {
        nodes {
          title
          relationships {
            field_image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const imagesForLightbox = data.allNodePhotoGallery.nodes
    .filter(node => node.title === tabs) // Filter by the active tab title
    .flatMap(node => {
      const images = node.relationships.field_image

      if (images) {
        return images
          .filter(image => image?.localFile?.publicURL) // Check for publicURL
          .map(image => ({
            src: image.localFile.publicURL, // Use publicURL for Lightbox
            alt: `Photo`,
          }))
      }
      return []
    })

  const indexOfLastImage = currentPage * imagesPerPage
  const indexOfFirstImage = indexOfLastImage - imagesPerPage
  const currentImages = imagesForLightbox.slice(
    indexOfFirstImage,
    indexOfLastImage
  )

  const imageElements = currentImages.map((image, i) => (
    <div
      key={i}
      className="cursor-pointer overflow-hidden"
      onClick={() => handleImageClick(i + indexOfFirstImage)}
      data-aos="zoom-in"
    >
      <GatsbyImage
        image={getImage(
          data.allNodePhotoGallery.nodes
            .find(node =>
              node.relationships.field_image.find(
                img => img.localFile.publicURL === image.src
              )
            )
            .relationships.field_image.find(
              img => img.localFile.publicURL === image.src
            ).localFile.childImageSharp
        )}
        className="object-cover w-full lg:h-[300px] h-full transition duration-300 hover:scale-110 grayscale hover:grayscale-0"
        alt={image.alt}
      />
    </div>
  ))

  const handleImageClick = index => {
    setLightboxIndex(index)
    setLightboxOpen(true)
  }

  const handleTabSelect = index => {
    setActiveTab(index)
    const selectedTab = tabNames[index]
    setTabs(selectedTab)
  }

  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    setModalOpen(true)
  }, [activeTab])

  const pageNumbers = []
  for (
    let i = 1;
    i <= Math.ceil(imagesForLightbox.length / imagesPerPage);
    i++
  ) {
    pageNumbers.push(i)
  }

  const closeIcon = <p></p>

  const tabData = [
    {
      name: "Financial Participatory Approach",
      content: <FPA />,
    },
    {
      name: "Awareness",
      content: <Awareness />,
    },
    {
      name: "Participatory Rural Appraisal",
      content: <PRA />,
    },
    {
      name: "Others",
      content: (
        <>
          <h2>Will available soon.</h2>
        </>
      ),
    },
  ]

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <Layout>
        <div className="lg:mx-20 mx-2 my-20 space-y-5">
          <p className="text-2xl font-bold text-center">
            Photos of Umiew Catchment
          </p>
          <Tabs
            selectedIndex={activeTab}
            onSelect={handleTabSelect}
            className="space-y-5"
          >
            <TabList className="text-white grid lg:grid-cols-4 grid-cols-1 gap-x-3 gap-y-3 text-center font-bold">
              {tabNames.map((tabName, index) => (
                <Tab
                  key={index}
                  className={`cursor-pointer bg-slate-900 px-2 py-2 hover:bg-white hover:text-black hover:border-b-4 hover:border-slate-900 ${
                    activeTab === index
                      ? "border-b-4 border-slate-900 bg-white text-black"
                      : ""
                  }`}
                >
                  {tabName}
                </Tab>
              ))}
            </TabList>
            {tabNames.map((tabName, index) => (
              <TabPanel key={index}>
                {activeTab === index && (
                  <div>
                    {imagesForLightbox.length > 0 ? (
                      <div className="space-y-5">
                        {tabData.map(
                          (tab, index) =>
                            tabName === tab.name && (
                              <center key={index}>
                                <div
                                  onMouseEnter={() => setColorSVG(true)}
                                  onMouseLeave={() => setColorSVG(false)}
                                  onClick={() => setModalOpen(true)}
                                  className="bg-slate-900 text-white px-2 py-1 text-sm flex gap-1 w-fit rounded-full hover:bg-white hover:shadow shadow-black hover:text-black cursor-pointer"
                                >
                                  Read
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    className="w-3"
                                  >
                                    <path
                                      fill={colorsvg ? "#000000" : "#ffffff"}
                                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"
                                    />
                                  </svg>
                                </div>
                                <Modal
                                  open={modalOpen}
                                  onClose={() => setModalOpen(false)}
                                  closeIcon={closeIcon}
                                  center
                                >
                                  {tab.content}
                                  <button
                                    className="float-right bg-red-500 text-white px-2"
                                    onClick={() => setModalOpen(false)}
                                    title="Close read more"
                                  >
                                    ✖
                                  </button>
                                </Modal>
                              </center>
                            )
                        )}
                        <div className="space-y-3">
                          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
                            <div className="col-span-2">{imageElements[0]}</div>
                            <div className="col-span-2">{imageElements[1]}</div>
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
                            <div>{imageElements[2]}</div>
                            <div className="col-span-3">{imageElements[3]}</div>
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
                            <div className="col-span-2">{imageElements[4]}</div>
                            <div>{imageElements[5]}</div>
                            <div>{imageElements[6]}</div>
                          </div>
                          <div className="grid grid-cols-1 sm:grid-cols-4 gap-3">
                            <div className="col-span-2">{imageElements[7]}</div>
                            <div className="col-span-2">{imageElements[8]}</div>
                          </div>
                        </div>
                        <div>
                          <ul className="flex justify-center">
                            {pageNumbers.map(number => (
                              <li key={number}>
                                <button
                                  onClick={() => paginate(number)}
                                  className="bg-gray-300 border border-gray-500 hover:bg-gray-500 hover:text-white px-3 py-1"
                                >
                                  {number}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <h1 className="text-center text-xl text-red-500 font-bold">
                        No Photos available
                      </h1>
                    )}
                  </div>
                )}
              </TabPanel>
            ))}
            <Lightbox
              index={lightboxIndex}
              open={lightboxOpen}
              close={() => setLightboxOpen(false)}
              slides={imagesForLightbox}
            />
          </Tabs>
        </div>
      </Layout>
    </div>
  )
}

export default PhotoUCMU
