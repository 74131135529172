import React, { useState, useEffect } from "react"
import ReactEcharts from "echarts-for-react"
import { useInView } from "react-intersection-observer"

const FinancingPatternChart = () => {
  const [chartKey, setChartKey] = useState(0) // Used to re-render the chart
  const { ref, inView } = useInView({
    triggerOnce: false, // The animation will happen every time it enters the viewport
    threshold: 0.5, // Trigger when 50% of the chart is visible
  })

  useEffect(() => {
    if (inView) {
      setChartKey(prev => prev + 1) // Increment key to trigger chart re-render
    }
  }, [inView])

  const option = {
    title: {
      left: "center",
      top: "bottom",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Government of Meghalaya", "Grant Amount", "Loan Amount"],
      textStyle: {
        fontSize: 16, // Increase this value to make the legend text larger
        color: "#000", // Adjust the color if needed
      },
    },
    series: [
      {
        name: "Financing",
        type: "pie",
        radius: ["100%", "50%"], // Adjust the radius to create a doughnut chart
        avoidLabelOverlap: false,
        startAngle: 0,
        animationDuration: 2000,
        animationEasing: "cubicInOut",
        animationType: "rotate",
        label: {
          show: false, // Hide labels by default
        },
        emphasis: {
          label: {
            show: true, // Show label on hover over the legend
            fontSize: 16,
            fontWeight: "bold",
            formatter: "{b}: {c}",
            color: "#000",
          },
        },
        labelLine: {
          show: false, // Disable label lines for the center label
        },
        data: [
          {
            value: 8,
            name: "Government of Meghalaya",
            itemStyle: { color: "#172554" },
          },
          { value: 1, name: "Grant Amount", itemStyle: { color: "#450a0a" } },
          { value: 32, name: "Loan Amount", itemStyle: { color: "#052e16" } },
        ],
      },
    ],
    graphic: {
      elements: [
        {
          type: "text",
          left: "center",
          top: "center",
          style: {
            text: "Total\n41m", // Text to display
            textAlign: "center",
            fill: "#000", // Text color
            fontSize: 24, // Text font size
            fontWeight: "bold",
          },
        },
      ],
    },
  }

  return (
    <center className="space-y-5">
      <p className="text-center text-2xl font-bold relative">
        Total Project Cost in EUR (€ Million)
      </p>
      <div ref={ref}>
        <ReactEcharts
          key={chartKey} // Re-render the chart when the key changes
          option={option}
          style={{ width: "100%" }}
        />
      </div>
    </center>
  )
}

export default FinancingPatternChart
