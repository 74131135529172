import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import Animate from "../components/animate"
import Outputone from "../images/outputone.jpeg"
import Outputtwo from "../images/outputtwo.jpg"
import Outputthree from "../images/outputthree.jpeg"
import Outputfour from "../images/outputfour.jpeg"
import "./nav.css"

function ProjectOutput() {
  const data = useStaticQuery(graphql`
    query {
      allNodeProjectComponent {
        nodes {
          title
          body {
            processed
          }
        }
      }
    }
  `)
  const nodes = data.allNodeProjectComponent.nodes
  const [outputone, getoutputone] = useState(false)
  const [outputtwo, getoutputtwo] = useState(false)
  const [outputthree, getoutputthree] = useState(false)
  const [outputfour, getoutputfour] = useState(false)

  const enteroutputone = () => {
    getoutputone(true)
  }
  const leaveoutputone = () => {
    getoutputone(false)
  }

  const enteroutputtwo = () => {
    getoutputtwo(true)
  }
  const leaveoutputtwo = () => {
    getoutputtwo(false)
  }

  const enteroutputthree = () => {
    getoutputthree(true)
  }
  const leaveoutputthree = () => {
    getoutputthree(false)
  }

  const enteroutputfour = () => {
    getoutputfour(true)
  }
  const leaveoutputfour = () => {
    getoutputfour(false)
  }
  return (
    <Animate>
      <div className="space-y-3 lg:px-10 px-3 py-5 bg-zinc-100">
        <h2 className="text-3xl font-bold custom-snow-blue">
          PROJECT OUTPUT
        </h2>
        <div className="flex lg:flex-row flex-col justify-center gap-x-5 gap-y-5 text-white">
          <div
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Outputone})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onMouseEnter={enteroutputone}
            onMouseLeave={leaveoutputone}
            className={`cursor-pointer rounded shadow lg:h-[450px] h-full py-20 flex flex-col w-full ${
              outputone
                ? "lg:w-[450px] transition-all duration-500 grow"
                : "justify-center grayscale lg:w-[400px]"
            }`}
          >
            <div className="space-y-5">
              <p
                className={`font-bold text-3xl text-center ${
                  outputone === true ? "hidden" : ""
                }`}
              >
                OUTPUT 1
              </p>
              <div
                className={`flex gap-x-3 justify-center ${
                  outputone === true ? "hidden" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                >
                  <path d="M6.72 20.492c1.532.956 3.342 1.508 5.28 1.508 1.934 0 3.741-.55 5.272-1.503l1.24 1.582c-1.876 1.215-4.112 1.921-6.512 1.921-2.403 0-4.642-.708-6.52-1.926l1.24-1.582zm6.405-.992l-.594.391c-.077.069-.19.109-.307.109h-.447c-.118 0-.231-.04-.308-.109l-.594-.391h2.25zm10.875-.5h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.883.586 1.414zm-18 0h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.883.586 1.414zm7.146-.5c.138 0 .25.112.25.25s-.112.25-.25.25h-2.279c-.138 0-.25-.112-.25-.25s.112-.25.25-.25h2.279zm.247-.5c0-2.002 1.607-2.83 1.607-4.614 0-1.86-1.501-2.886-3.001-2.886s-2.999 1.024-2.999 2.886c0 1.784 1.607 2.639 1.607 4.614h2.786zm7.607-6c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm-18 0c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm12.87 2.385l1.349.612-.413.911-1.298-.588c.15-.3.275-.608.362-.935zm-7.739 0c.087.332.208.631.36.935l-1.296.588-.414-.911 1.35-.612zm9.369-1.885v1h-1.501c.01-.335-.02-.672-.093-1h1.594zm-9.406 0c-.072.327-.102.663-.092.997v.003h-1.502v-1h1.594zm6.928-1.714l1.242-.882.579.816-1.252.888c-.146-.291-.335-.566-.569-.822zm-6.044-.001c-.23.252-.418.525-.569.823l-1.251-.888.578-.816 1.242.881zm4.435-1.046l.663-1.345.897.443-.664 1.345c-.278-.184-.58-.332-.896-.443zm-2.826-.001c-.315.11-.618.258-.897.442l-.663-1.343.897-.443.663 1.344zm-2.587-9.054v2.149c-2.938 1.285-5.141 3.942-5.798 7.158l-2.034-.003c.732-4.328 3.785-7.872 7.832-9.304zm8 0c4.047 1.432 7.1 4.976 7.832 9.304l-2.034.003c-.657-3.216-2.86-5.873-5.798-7.158v-2.149zm-3.5 8.846c-.334-.039-.654-.041-1-.001v-1.529h1v1.53zm2.5-2.53h-6c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.884.586 1.414zm-3-7c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                </svg>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#ffffff"
                >
                  <path d="M16 24h-6v-1c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.884.586 1.414v1zm8 0h-6v-1c0-1.105.895-2 2-2h2c.53 0 1.039.211 1.414.586s.586.884.586 1.414v1zm-17.241-15c.649 0 1.293-.213 1.692-.436.755-.42 2.695-1.643 3.485-2.124.216-.131.495-.083.654.113l.008.011c.165.204.146.499-.043.68-.622.597-2.443 2.328-3.37 3.213-.522.499-.822 1.183-.853 1.904-.095 2.207-.261 6.912-.331 8.833-.017.45-.387.806-.837.806h-.001c-.444 0-.786-.347-.836-.788-.111-.981-.329-3.28-.426-4.212-.04-.384-.28-.613-.585-.615-.304-.001-.523.226-.549.609-.061.921-.265 3.248-.341 4.22-.034.442-.397.786-.84.786h-.001c-.452 0-.824-.357-.842-.808-.097-2.342-.369-8.964-.369-8.964l-1.287 2.33c-.14.253-.445.364-.715.26h-.001c-.279-.108-.43-.411-.349-.698l1.244-4.393c.122-.43.515-.727.962-.727h4.531zm6.241 7c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm8 0c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm3-1h-15v-1h8v-1h3v1h3v-13h-22v7l-1 2.745v-10.745h24v15zm-6-6h-4v-1h4v1zm-12.626-5c1.241 0 2.25 1.008 2.25 2.25s-1.009 2.25-2.25 2.25c-1.242 0-2.25-1.008-2.25-2.25s1.008-2.25 2.25-2.25zm15.626 3h-7v-1h7v1zm0-2h-7v-1h7v1z" />
                </svg>
              </div>
            </div>
            <div className="space-y-5  px-5">
              {outputone && (
                <div className="text-xl font-bold" data-aos="fade-up">
                  {nodes[0].title}
                </div>
              )}
              {outputone && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: nodes[0].body.processed,
                  }}
                  data-aos="fade-down"
                />
              )}
            </div>
          </div>
          <div
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Outputtwo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onMouseEnter={enteroutputtwo}
            onMouseLeave={leaveoutputtwo}
            className={`cursor-pointer rounded shadow lg:h-[450px] h-full py-20 flex flex-col w-full ${
              outputtwo
                ? "lg:w-[450px] transition-all duration-500 grow"
                : "justify-center grayscale lg:w-[400px]"
            }`}
          >
            <div className="space-y-5">
              <p
                className={`font-bold text-3xl text-center ${
                  outputtwo === true ? "hidden" : ""
                }`}
              >
                OUTPUT 2
              </p>
              <div
                className={`flex gap-x-3 justify-center ${
                  outputtwo === true ? "hidden" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                >
                  <path d="M23.322 19.318l-4.072 1.192-1.193-4.072 1.763.964c.737-1.305 1.18-2.798 1.18-4.402 0-3.681-2.224-6.849-5.396-8.242l.623-1.914c3.975 1.657 6.772 5.579 6.772 10.156 0 1.953-.521 3.779-1.416 5.367l1.739.951zm-11.323 2.682c-3.332 0-6.24-1.825-7.796-4.523l1.769-1.026-4.102-1.089-1.089 4.1 1.694-.982c1.899 3.295 5.447 5.52 9.524 5.52 2.202 0 4.248-.654 5.969-1.768l-1.24-1.588c-1.376.854-2.994 1.356-4.729 1.356zm-8.994-8.876l-.006-.124c0-4.625 3.51-8.433 8.004-8.932l-.004 1.932 3.005-2.996-2.995-3.004-.003 2.05c-5.61.503-10.007 5.21-10.007 10.95l.021.402 1.985-.278zm6.126-.564c-.251.862-.146 1.768.415 2.571.904-1.333 2.588-2.767 3.76-3.039-2.395 1.731-3.735 4.16-3.909 6.908h1.011c-.023-1.038.125-2.345.497-2.934.911.302 1.924.125 2.797-.613 1.724-1.463 1.077-5.14 4.112-5.564-4.07-2.224-7.943.124-8.683 2.671z" />
                </svg>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#ffffff"
                >
                  <path d="M20.655 22.122v-2.197c1.907-.418 3.345-2.235 3.345-4.41 0-1.381-.292-1.662-3.536-10.041-.012 0-.16-.459-.646-.459-.488 0-.633.461-.643.461-.611 1.58-1.11 2.863-1.528 3.937.73 1.813.853 2.414.853 3.435 0 1.969-.884 3.737-2.289 4.935.59 1.085 1.59 1.883 2.771 2.142v1.744c-1.538-.339-3.53-.586-6.065-.637v-2.761c2.599-.436 4.583-2.7 4.583-5.423 0-1.687-.384-2.031-4.653-12.271-.014 0-.209-.562-.847-.562-.643 0-.833.564-.847.564-4.633 11.145-4.653 10.837-4.653 12.269 0 2.723 1.984 4.987 4.583 5.423v2.761c-2.562.051-4.571.303-6.116.648v-1.755c1.186-.263 2.187-1.077 2.769-2.186-1.375-1.198-2.236-2.946-2.236-4.891 0-.934.024-1.277.844-3.324-.424-1.088-.938-2.41-1.567-4.05-.011 0-.157-.459-.638-.459-.484 0-.627.461-.637.461-3.487 9.119-3.502 8.867-3.502 10.039 0 2.175 1.423 3.992 3.311 4.41v2.208c-2.587.852-3.311 1.882-3.311 1.882h24s-.732-1.038-3.345-1.893z" />
                </svg>
              </div>
            </div>
            <div className="space-y-5  px-5">
              {outputtwo && (
                <div className="text-xl font-bold" data-aos="fade-up">
                  {nodes[1].title}
                </div>
              )}
              {outputtwo && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: nodes[1].body.processed,
                  }}
                  data-aos="fade-down"
                />
              )}
            </div>
          </div>
          <div
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Outputthree})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onMouseEnter={enteroutputthree}
            onMouseLeave={leaveoutputthree}
            className={`cursor-pointer rounded shadow lg:h-[450px] h-full py-20 flex flex-col w-full ${
              outputthree
                ? "lg:w-[450px] transition-all duration-500 grow"
                : "justify-center grayscale lg:w-[400px]"
            }`}
          >
            <div className="space-y-5">
              <p
                className={`font-bold text-3xl text-center ${
                  outputthree === true ? "hidden" : ""
                }`}
              >
                OUTPUT 3
              </p>
              <div
                className={`flex gap-x-3 justify-center ${
                  outputthree === true ? "hidden" : ""
                }`}
              >
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#ffffff"
                >
                  <path d="M7 16.488l1.526-.723c1.792-.81 2.851-.344 4.349.232 1.716.661 2.365.883 3.077 1.164 1.278.506.688 2.177-.592 1.838-.778-.206-2.812-.795-3.38-.931-.64-.154-.93.602-.323.818 1.106.393 2.663.79 3.494 1.007.831.218 1.295-.145 1.881-.611.906-.72 2.968-2.909 2.968-2.909.842-.799 1.991-.135 1.991.72 0 .23-.083.474-.276.707-2.328 2.793-3.06 3.642-4.568 5.226-.623.655-1.342.974-2.204.974-.442 0-.922-.084-1.443-.25-1.825-.581-4.172-1.313-6.5-1.6v-5.662zm-1 6.538h-4v-8h4v8zm1-7.869v-1.714c-.006-1.557.062-2.447 1.854-2.861 1.963-.453 4.315-.859 3.384-2.577-2.761-5.092-.787-7.979 2.177-7.979 2.907 0 4.93 2.78 2.177 7.979-.904 1.708 1.378 2.114 3.384 2.577 1.799.415 1.859 1.311 1.853 2.879 0 .13-.011 1.171 0 1.665-.483-.309-1.442-.552-2.187.106-.535.472-.568.504-1.783 1.629-1.75-.831-4.456-1.883-6.214-2.478-.896-.304-2.04-.308-2.962.075l-1.683.699z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                >
                  <path d="M7 24h-6v-6h6v6zm8-9h-6v9h6v-9zm8-4h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z" />
                </svg>
              </div>
            </div>
            <div className="space-y-5  px-5">
              {outputthree && (
                <div className="text-xl font-bold" data-aos="fade-up">
                  {nodes[2].title}
                </div>
              )}
              {outputthree && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: nodes[2].body.processed,
                  }}
                  data-aos="fade-down"
                />
              )}
            </div>
          </div>
          <div
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Outputfour})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            onMouseEnter={enteroutputfour}
            onMouseLeave={leaveoutputfour}
            className={`cursor-pointer rounded shadow lg:h-[450px] h-full py-20 flex flex-col w-full ${
              outputfour
                ? "lg:w-[450px] transition-all duration-500 grow"
                : "justify-center grayscale lg:w-[400px]"
            }`}
          >
            <div className="space-y-5">
              <p
                className={`font-bold text-3xl text-center ${
                  outputfour === true ? "hidden" : ""
                }`}
              >
                OUTPUT 4
              </p>
              <div
                className={`flex gap-x-3 justify-center ${
                  outputfour === true ? "hidden" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                >
                  <path d="M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.081 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h10.483l.704-3h1.615l.704 3h10.483l.005-1.241c.001-2.52-.198-3.975-3.177-4.663zm-8.231 1.904h-1.164l-.91-2h2.994l-.92 2z" />
                </svg>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  fill="#ffffff"
                >
                  <path d="M24 19h-1v-2.2c-1.853 4.237-6.083 7.2-11 7.2-6.623 0-12-5.377-12-12h1c0 6.071 4.929 11 11 11 4.66 0 8.647-2.904 10.249-7h-2.249v-1h4v4zm-11.036 0h-1.886c-.34-.957-.437-1.571-1.177-1.878h-.001c-.743-.308-1.251.061-2.162.494l-1.333-1.333c.427-.899.804-1.415.494-2.163-.308-.74-.926-.839-1.878-1.177v-1.886c.954-.339 1.57-.437 1.878-1.178.308-.743-.06-1.248-.494-2.162l1.333-1.333c.918.436 1.421.801 2.162.494l.001-.001c.74-.307.838-.924 1.177-1.877h1.886c.34.958.437 1.57 1.177 1.877l.001.001c.743.308 1.252-.062 2.162-.494l1.333 1.333c-.435.917-.801 1.421-.494 2.161v.001c.307.739.915.835 1.878 1.178v1.886c-.953.338-1.571.437-1.878 1.178-.308.743.06 1.249.494 2.162l-1.333 1.333c-.92-.438-1.42-.802-2.157-.496-.746.31-.844.926-1.183 1.88zm-.943-4.667c-1.289 0-2.333-1.044-2.333-2.333 0-1.289 1.044-2.334 2.333-2.334 1.289 0 2.333 1.045 2.333 2.334 0 1.289-1.044 2.333-2.333 2.333zm-8.021-5.333h-4v-4h1v2.2c1.853-4.237 6.083-7.2 11-7.2 6.623 0 12 5.377 12 12h-1c0-6.071-4.929-11-11-11-4.66 0-8.647 2.904-10.249 7h2.249v1z" />
                </svg>
              </div>
            </div>
            <div className="space-y-5  px-5">
              {outputfour && (
                <div className="text-xl font-bold" data-aos="fade-up">
                  {nodes[3].title}
                </div>
              )}
              {outputfour && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: nodes[3].body.processed,
                  }}
                  data-aos="fade-down"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default ProjectOutput
