import React, { useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons"
import PageLayout from "../components/Page_layout"

const Tenderdisplay = ({ notices }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filterByDate, setFilterByDate] = useState("")

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value)
  }
  const handleFilterByDateChange = event => {
    const selectedDate = event.target.value
    setFilterByDate(selectedDate)

    // Convert selected date to "DD-MM-YYYY" format
    const formattedDate = selectedDate
      ? selectedDate.split("-").reverse().join("-")
      : ""

    setFilterByDate(formattedDate)
  }
  const filteredNotices = notices
    .filter(notice =>
      filterByDate ? notice.field_date === filterByDate : true
    )
    .sort((a, b) => {
      // Assuming notice. field_date_tender is in the format "DD-MM-YYYY"
      const aDateStr = a.field_date
        ? a.field_date.split("-").reverse().join("-")
        : null
      const bDateStr = b.field_date
        ? b.field_date.split("-").reverse().join("-")
        : null

      const dateA = aDateStr ? new Date(aDateStr) : null
      const dateB = bDateStr ? new Date(bDateStr) : null
      return dateB - dateA // Descending order
    })
    .slice(0, rowsPerPage)

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 flex flex-col">
          <h2 className="text-2xl text-center font-bold">Notifications</h2>
          <div className="flex gap-x-2 ml-auto mt-10">
            <h2 className="self-center">Filter</h2>
            <div className="self-center">
              <select
                id="rowsPerPage"
                onChange={handleRowsPerPageChange}
                className="w-full py-2 px-2 border border-gray-300 rounded"
              >
                <option value={1}>1</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                {/* Add more options if needed */}
              </select>
            </div>
            <div>
              <input
                type="date"
                id="filterByDate"
                onChange={handleFilterByDateChange}
                value={filterByDate}
                className="w-full py-2 px-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="mt-2">
            {filteredNotices > 0 ? (
              <table className="w-full custom-table bg-white">
                <thead className="bg-black text-white font-bold">
                  <tr className="text-center text-white">
                    <td>Sl. No</td>
                    <td>Date</td>
                    <td>Title</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredNotices.map((nodes, index) => (
                    <tr key={nodes.title}>
                      <td className="w-20 text-center">{index + 1}</td>
                      <td className="w-28 text-center">{nodes.field_date}</td>
                      <td>{nodes.title}</td>
                      <td className="w-32">
                        <Link
                          to={nodes.path.alias}
                          className="hover:text-blue-500 text-center"
                        >
                          <b>
                            {" "}
                            <FontAwesomeIcon
                              icon={faFileAlt}
                              className="px-2"
                            />
                          </b>
                          View More
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center font-bold">No Data Available</p>
            )}
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

Tenderdisplay.propTypes = {
  notices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      field_date: PropTypes.string.isRequired,
      // Add more prop types for other fields if needed
    })
  ).isRequired,
}

const YourPage = ({ data }) => {
  const notices = data.allNodeNotification.nodes

  return (
    <div>
      <Tenderdisplay notices={notices} />
    </div>
  )
}

export const query = graphql`
  query {
    allNodeNotification(
      sort: { field_date: DESC }
      filter: { field_select_one_notice: { eq: "notice" } }
    ) {
      nodes {
        title
        path {
          alias
        }
        field_date(formatString: "DD-MM-YYYY")
      }
    }
  }
`

export default YourPage
