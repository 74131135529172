import React from "react"
import PageLayout from "../components/Page_layout"
import { graphql } from "gatsby"
import FinancialChart from "../components/financial-chart"

const About = ({ data }) => {
  const article = data.allNodePage.nodes
  const page = data.page.nodes
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20 lg:px-10 lg:py-10 py-5 px-3 border shadow space-y-10 rounded-3xl bg-white">
          <div>
            {article.map((e, index) => (
              <div key={index} className="space-y-3">
                <p className="font-bold text-2xl text-center">{e.title}</p>
                <div className="space-y-10">
                  <p
                    dangerouslySetInnerHTML={{ __html: e.body.processed }}
                    className="leading-8 text-justify space-y-5 font-semibold"
                  />
                  <p
                    className="text-justify space-y-5 font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: e.field_body.processed,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <hr className="border-gray-300" />
          <center>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-10 gap-x-10">
              {page.map(node => (
                <div className="space-y-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="w-14"
                  >
                    <path
                      fill="#ef4444"
                      d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
                    />
                  </svg>
                  <h2 className="text-2xl font-bold">{node.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: node.body.processed }}
                  />
                </div>
              ))}
              <div className="space-y-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="w-14"
                >
                  <path
                    fill="#eab308"
                    d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z"
                  />
                </svg>
                <p className="text-2xl font-bold">Project Duration</p>
                <p>
                  25<sup>th</sup> Nov, 2022 - 24<sup>th</sup> Nov, 2029
                </p>
              </div>
              <div className="space-y-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="w-14"
                >
                  <path
                    fill="#3b82f6"
                    d="M384 476.1L192 421.2l0-385.3L384 90.8l0 385.3zm32-1.2l0-386.5L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3l0 334.8c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2l0 386.5L32.9 474.5C17.1 480.8 0 469.2 0 452.2L0 117.4c0-9.8 6-18.6 15.1-22.3z"
                  />
                </svg>
                <p className="text-2xl font-bold">Project Coverage</p>
                <div>
                  <p>225 villages, 10 blocks and 4 districts</p>
                  <p>Across 2 catchments</p>
                </div>
              </div>
              <div className="space-y-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="w-14"
                >
                  <path
                    fill="#22c55e"
                    d="M0 112.5L0 422.3c0 18 10.1 35 27 41.3c87 32.5 174 10.3 261-11.9c79.8-20.3 159.6-40.7 239.3-18.9c23 6.3 48.7-9.5 48.7-33.4l0-309.9c0-18-10.1-35-27-41.3C462 15.9 375 38.1 288 60.3C208.2 80.6 128.4 100.9 48.7 79.1C25.6 72.8 0 88.6 0 112.5zM288 352c-44.2 0-80-43-80-96s35.8-96 80-96s80 43 80 96s-35.8 96-80 96zM64 352c35.3 0 64 28.7 64 64l-64 0 0-64zm64-208c0 35.3-28.7 64-64 64l0-64 64 0zM512 304l0 64-64 0c0-35.3 28.7-64 64-64zM448 96l64 0 0 64c-35.3 0-64-28.7-64-64z"
                  />
                </svg>
                <p className="text-2xl font-bold">Estimated Cost</p>
                <p>344 Cr</p>
              </div>
            </div>
          </center>
          <hr className="border-gray-300" />
          <FinancialChart />
        </div>
      </PageLayout>
    </div>
  )
}

export const query = graphql`
  query {
    allNodePage(
      filter: { id: { eq: "ead74314-53fe-5629-94af-85e3d31b4c0c" } }
    ) {
      nodes {
        title
        id
        body {
          processed
        }
        field_body {
          processed
        }
      }
    }
    page: allNodePage(
      filter: { id: { eq: "ce303857-f7ca-5ba9-b357-7bb87de0f74c" } }
    ) {
      nodes {
        title
        body {
          processed
        }
      }
    }
  }
`
export default About
