import React, { useState } from "react"
import PageLayout from "../components/Page_layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import ReactPaginate from "react-paginate"

function TrainingMaterials() {
  const data = useStaticQuery(graphql`
    query {
      allNodeTrainingMaterials(sort: { title: ASC }) {
        nodes {
          title
          relationships {
            field_training_materials {
              localFile {
                url
              }
            }
          }
        }
      }
    }
  `)

  const training = data.allNodeTrainingMaterials.nodes

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")
  const itemsPerPage = 10

  // Filtered data based on the search term
  const filteredTraining = training.filter(node =>
    node.title.toLowerCase().startsWith(searchTerm.toLowerCase())
  )

  // Get the current items for the page
  const offset = currentPage * itemsPerPage
  const currentItems = filteredTraining.slice(offset, offset + itemsPerPage)
  const pageCount = Math.ceil(filteredTraining.length / itemsPerPage)

  // Handle page click
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  // Handle search input change
  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
    setCurrentPage(0) // Reset to the first page after filtering
  }

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20 space-y-5">
          <div className="flex justify-between">
            <p className="text-2xl font-bold">Training Materials</p>
            <input
              type="text"
              className="w-1/4 rounded border border-gray-500 py-1 pl-3"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <table className="mt-2 custom-table bg-white">
            <thead className="bg-black text-white font-bold">
              <tr className="text-center text-white">
                <td>Sl. No</td>
                <td>Title</td>
                <td colSpan="2">Action</td>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((node, index) => (
                <tr key={index}>
                  <td className="w-20 text-center">
                    {offset + index + 1} {/* Adjusted Sl. No */}
                  </td>
                  <td>{node.title}</td>
                  <td className="w-0">
                    <center>
                      <Link
                        className="py-1 flex w-fit gap-x-1 px-3 bg-slate-500 hover:bg-slate-900 text-white rounded-full"
                        to={`/view-ppt?file=${encodeURIComponent(
                          node.relationships.field_training_materials.localFile
                            .url
                        )}&title=${encodeURIComponent(node.title)}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          className="w-5"
                        >
                          <path
                            fill="#ffffff"
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                          />
                        </svg>
                        View
                      </Link>
                    </center>
                  </td>
                  <td className="w-0">
                    <a
                      className="py-1 flex w-fit gap-x-1 px-3 bg-slate-500 hover:bg-slate-900 text-white rounded-full"
                      download
                      href={
                        node.relationships.field_training_materials.localFile
                          .url
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-5"
                      >
                        <path
                          fill="#ffffff"
                          d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM244.7 395.3l-112-112c-4.6-4.6-5.9-11.5-3.5-17.4s8.3-9.9 14.8-9.9l64 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 64 0c6.5 0 12.3 3.9 14.8 9.9s1.1 12.9-3.5 17.4l-112 112c-6.2 6.2-16.4 6.2-22.6 0z"
                        />
                      </svg>
                      Download
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination flex justify-center"}
            activeClassName={"text-white bg-slate-500"}
            previousLinkClassName={
              "prev-link px-1 py-2 hover:text-blue-500 border border-gray-300 bg-white"
            }
            nextLinkClassName={
              "next-link px-1 py-2 hover:text-blue-500 border border-gray-300 bg-white"
            }
            disabledClassName={"disabled text-gray-300 bg-white"}
            pageLinkClassName={
              "page-link px-5 py-2 hover:text-white hover:bg-slate-500 border border-gray-300"
            }
          />
        </div>
      </PageLayout>
    </div>
  )
}

export default TrainingMaterials
