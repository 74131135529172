import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const PhotoGallery = () => {
  return (
    <div className="mx-3 sm:mx-20 space-y-3">
      <h2 className="text-3xl font-bold custom-snow-blue">PHOTO GALLERY</h2>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-5 gap-y-5">
        <Link
          to="/photos-ganol-catchment"
          className="overflow-hidden relative group rounded shadow-lg shadow-black"
          data-aos="zoom-in"
        >
          <StaticImage
            src="../images/ganol.jpeg"
            className="w-full h-full transition duration-300 group-hover:scale-110"
            alt="Ganol Catchment"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center font-bold text-3xl">
            Ganol Catchment
          </div>
        </Link>
        <Link
          to="/more-photos"
          className="overflow-hidden relative group rounded shadow-lg shadow-black"
          data-aos="zoom-in"
        >
          <StaticImage
            src="../images/exposure.jpeg"
            className="w-full h-full transition duration-300 group-hover:scale-110"
            alt="Ganol Catchment"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center font-bold text-3xl">
            More Photos
          </div>
        </Link>
        <Link
          to="/photos-umiew-catchment"
          className="overflow-hidden relative group rounded shadow-lg shadow-black"
          data-aos="zoom-in"
        >
          <StaticImage
            src="../images/umiew.jpeg"
            className="w-full h-full transition duration-300 group-hover:scale-110"
            alt="Umiew Catchment"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center font-bold text-3xl">
            Umiew Catchment
          </div>
        </Link>
      </div>
    </div>
  )
}

export default PhotoGallery
