import React, { useState } from "react"

function AwarenessUmiew() {
  const [readmore, setReadmore] = useState(false)
  return (
    <div className="space-y-3">
      <h1 className="text-center font-bold">Awareness Programme</h1>
      <p className="text-justify leading-8">
        The awareness program under the MegARISE project focuses on promoting
        ecological restoration and sustainable water management in Meghalaya. It
        aims to empower communities by educating them about their roles and
        responsibilities and fostering active participation. Conducting
        awareness programs under the MegARISE project is essential for the
        following reasons:
      </p>
      <button
        onClick={() => setReadmore(!readmore)}
        className={`text-blue-500 text-xs hover:text-blue-950 ${
          readmore ? "hidden" : "block"
        }`}
      >
        Read more
      </button>
      {readmore && (
        <div className="space-y-3 text-justify">
          <ul className="list-decimal space-y-3 px-5">
            <li>
              Sustainable Practices: Emphasizing the importance of adopting
              sustainable methods to conserve water resources, protect forests,
              and preserve biodiversity.
            </li>
            <li>
              Capacity Building: Equipping local populations with the knowledge
              and skills needed to manage natural resources efficiently and make
              informed decisions.
            </li>
            <li>
              Livelihood Enhancement: Demonstrating how conservation efforts can
              lead to improved economic outcomes by encouraging sustainable
              farming practices and linking communities to better market
              opportunities.
            </li>
          </ul>
          <p className="leading-8">
            By conducting such awareness programs, the project cultivates a
            shared understanding of its goals and instills a sense of ownership
            among communities—an essential foundation for long-term
            sustainability.
          </p>
          <p className="font-bold leading-8">
            {" "}
            So far, there are 47 awareness programs conducted under the
            Umiew-Catchment Management Unit. Here is a glimpse of the awareness
            program conducted.
          </p>
        </div>
      )}
    </div>
  )
}

export default AwarenessUmiew
