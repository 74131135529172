import React from "react"
import PageLayout from "../components/Page_layout"

function AccessibilityStatement() {
  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="mx-3 sm:mx-20 my-20 border shadow sm:px-5 px-3 py-5 space-y-5 rounded-3xl bg-white">
          <p className="text-2xl font-bold text-center">
            Accessibility Statement
          </p>
          <p className="text-justify leading-8">
            The official website of MegARISE, has been created with an aim to
            provide maximum accessibility and usability to its visitors. This
            website can be accessed from various devices such as desktop or
            laptop computers, web-enabled mobile devices etc. We aim to adhere
            to norms and adhere to the principles of usability and universal
            design, which should assist all visitors to this website. If you
            face any problem or have suggestion regarding accessibility of this
            website, please let us know the nature of problem along with your
            contact information to get back to us as soon as possible.
          </p>
        </div>
      </PageLayout>
    </div>
  )
}

export default AccessibilityStatement
