import React, { useState, useEffect } from "react"
import PageLayout from "../components/Page_layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ReactPaginate from "react-paginate"

function Newsletter() {
  const [updates, setUpdates] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const updatesPerPage = 8 // Number of updates per page

  useEffect(() => {
    fetch("https://be.meglife.in/jsonapi/node/update_of_the_week?page[limit]=10000")
      .then(response => response.json())
      .then(data => {
        console.log("Number of entries:", data.data.length)
        const updatesData = data.data.map(item => ({
          id: item.id,
          title: item.attributes.title,
          date: item.attributes.field_date,
          path: item.attributes.path.alias,
          hasPdf: item.relationships?.field_pdf_file?.data !== null,
        }))

        updatesData.sort((a, b) => new Date(b.date) - new Date(a.date))

        const pdfFetchPromises = updatesData.map(update => {
          if (update.hasPdf) {
            const pdfUrl = `https://be.meglife.in/jsonapi/node/update_of_the_week/${update.id}/field_pdf_file`

            return fetch(pdfUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error(`Failed to fetch PDF for ${update.id}`)
                }
                return response.json()
              })
              .then(pdfData => {
                if (pdfData && pdfData.data && pdfData.data.attributes) {
                  return {
                    ...update,
                    pdfUrl: `https://be.meglife.in${pdfData.data.attributes.uri.url}`,
                  }
                } else {
                  return { ...update, pdfUrl: null }
                }
              })
              .catch(error => {
                console.error(
                  `Error fetching PDF for update ${update.id}:`,
                  error
                )
                return { ...update, pdfUrl: null }
              })
          } else {
            return Promise.resolve({ ...update, pdfUrl: null })
          }
        })

        Promise.all(pdfFetchPromises).then(completeUpdates => {
          setUpdates(completeUpdates)
          setLoading(false)
        })
      })
      .catch(error => {
        console.error("Error fetching updates:", error)
        setLoading(false)
      })
  }, [])

  const formatDate = dateString => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  }

  const handlePageClick = event => {
    setCurrentPage(event.selected)
  }

  // Calculate the index of the first and last updates for the current page
  const startIndex = currentPage * updatesPerPage
  const endIndex = startIndex + updatesPerPage
  const currentUpdates = updates.slice(startIndex, endIndex) // Updates for the current page

  return (
    <div style={{ backgroundColor: "#f1f5f9" }}>
      <PageLayout>
        <div className="lg:mx-20 mx-3 my-20 space-y-5">
          <div className="text-center">
            <p className="text-2xl font-bold">MBDA/MBMA Newsletter</p>
            <p className="text-lg">Update of the Week</p>
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-1 gap-x-5 lg:gap-y-10 gap-y-5">
            {loading ? (
              <p>Loading...</p>
            ) : currentUpdates.length > 0 ? (
              currentUpdates.map((update, index) => (
                <div key={index} className="relative text-white">
                  <Link
                    to={`/view-pdf?pdfUrl=${encodeURIComponent(
                      update.pdfUrl
                    )}&title=${encodeURIComponent(update.title)}`}
                  >
                    <StaticImage src="../images/newsletter.jpg" />
                    <h2 className="font-bold uppercase text-center bg-[#ceb784] px-1">
                      {update.title}
                    </h2>
                    <p className="absolute top-0 left-0 py-1 px-1">
                      {formatDate(update.date)}
                    </p>
                  </Link>
                  <div>
                    {update.pdfUrl ? (
                      <Link
                        to={`/view-pdf?pdfUrl=${encodeURIComponent(
                          update.pdfUrl
                        )}&title=${encodeURIComponent(update.title)}`}
                      >
                        <p className="text-white hover:bg-slate-900 bg-slate-500 py-2 text-center">
                          Preview
                        </p>
                      </Link>
                    ) : (
                      <p>No PDF available</p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No Contents available</p>
            )}
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Math.ceil(updates.length / updatesPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination flex justify-center"}
            activeClassName={"active bg-slate-500 text-white"}
            previousLinkClassName={
              "prev-link px-1 py-2 hover:text-blue-500 border border-gray-300 bg-white"
            }
            nextLinkClassName={
              "next-link px-1 py-2 hover:text-blue-500 border border-gray-300 bg-white"
            }
            disabledClassName={"disabled text-gray-300"}
            pageLinkClassName={
              "page-link px-5 py-2 hover:text-white hover:bg-slate-500 border border-gray-300"
            }
          />
        </div>
      </PageLayout>
    </div>
  )
}

export default Newsletter
